* {
  margin: 0;
  padding: 0;
}

/* Notice Card styles */
.NoticeCard-main {
  display: flex;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  padding: 25px 20px;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 15px;
  max-height: 30px;
}

#NoticeCard-para {
  max-width: 30%;
  margin-left: 31%;

}

#NoticeCard-ed{
  margin-left: 18%;
}

#NoticeCard-delete{
  margin-left: 2vw;
}

/* Notice Information style */
.NoticeInformation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

#NoticeInformation-header-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-left: 1.5%;
}

.NoticeInformation-header-createNotice {
  background-color: #1a293c;
  color: #ffffff;
  padding: 5px 45px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
}

.NoticeCard-container {
  max-height: 62vh;
  overflow-y: auto;
  background-color: #dbdcdd;
  margin: 1vw;
  padding-left: 3vw;
}

.NoticeInformation-row {
  display: flex;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  margin-left: 2%;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
  font-weight: 600;
}

#NoticeInformation-row-ed{
  margin-left: 18%;
}

#NoticeInformation-row-details{
  margin-left: 31%;
}

#No-notice{
  font-family: 'Jaldi','sans-serif';
  text-align: center;
  font-weight: 600;
  font-size: 1.5vw;
}


.NoticeInformation-form-header {
  font-family: "Jaldi", "sans-serif";
  font-size: 2vw;
  margin-left: 45%;
  font-weight: 600;
}

.NoticeInformation-form {
  margin-left: 40%;
  max-width: 26vw;
}

.CreateNotice-input-group-issue-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.7vw;
}

#CreateNotice-issue-date {
  padding: 10px 40px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight:600
}

#CreateNotice-issueDateSpan {
  font-family: "Jaldi", "sans-serif";
  font-weight:600
}

.CreateNotice-input-group-effective-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.7vw;
}

#CreateNotice-effective-date {
  padding: 10px 40px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight:600
}

#CreateNotice-effectiveDateSpan {
  font-family: "Jaldi", "sans-serif";
  font-weight:600
}

.CreateNotice-input-group-noticeDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.8vw;
  font-family: "Jaldi", "sans-serif";
}

#CreateNotice-detailsSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 6vw;
  font-weight:600
}

#CreateNotice-details {
  background-color: #d9d9d9;
  resize: none;
  font-weight:600
}

#CreateNotice-submitButton {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 9px 95px;
  border-radius: 10px;
  margin-left: 3.4vw;
  margin-top: 2.8vw;
  margin-bottom: 2.8vw;
  font-family: "Jaldi", "sans-serif";
  font-size: 15px;
  cursor: pointer;
}

/* Media Queries for Responsive Design */

/* For tablets and larger screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .NoticeCard-main {
    padding: 25px 5px;
  }
  .NoticeInformation-header {
    padding: 2vw 2vw;
  }

  .NoticeInformation-header-createNotice {
    padding: 10px 60px;
  }

  .NoticeCard-container {
    margin: 2vw;
    padding-left: 5vw;
    max-height: 41vh;
  }

  #NoticeCard-para{
    margin-left: 23%;
  }

  #NoticeCard-ed{
    margin-left: 15%;
  }

  #NoticeInformation-row-ed{
    margin-left: 13%;
  }
  .NoticeInformation-form-header {
    font-size: 4vw;
    margin-left: 40%;
  }

  .NoticeInformation-form {
    margin-left: 32%;
    max-width: 40vw;
  }
}

/* For larger screens */
@media screen and (max-width: 767px) {

  .NoticeInformation-header{
    margin-top: 8%;
    width:106vw;
  }

  #NoticeInformation-header-title{
    font-size: 6.5vw;
  }

  .NoticeInformation-header-createNotice{
    padding: 2px 5px;
    width: 28vw;
  }
  .NoticeCard-main {
    padding: 40px 1px;
  }

  #NoticeCard-ed{
    margin-left: 11%;
  }

  #NoticeCard-para{
    margin-left: 7%;
    max-width: 40%;
  }

  #NoticeInformation-row-details{
    margin-left: 10%;
  }

  #NoticeInformation-row-ed{
    margin-left: 5%;
  }

  .NoticeCard-container {
    margin-left: 2%;
    max-height: 86vw;
    width:108vw;
  }

  .NoticeInformation-form-header{
    font-size: 7vw;
    margin-left: 35%;
  }

  .NoticeInformation-form{
    max-width: 94vw;
    margin-left: 4%;
  }

  #No-notice{
    font-size: 4.5vw;
  }
}
