@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jaldi:wght@400;700&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
}
/* Navbar.css */

.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1a293c;
  font-family: "Jaldi";
  color: white;
  text-align: center;
  padding-bottom: 0;
  z-index: 100; /* Ensure it's above other content */
}
.navbar {
  background-color: #1a293c;
  font-family: "Jaldi";
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: 0;
}

.navbar a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
  color: #9eb4d0;
}

/* Top Section */

.left {
  flex: 1;
  justify-content: flex-start;
}

.middle {
  flex: 3;
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
}

#navLogo {
  width: 30%;
  margin: 10px;
  margin-right: 36%;
  border-radius: 15%;
}

#Navlogin,
#Navsignup,
#Navmember,
#NavBlog {
  margin: 0 2.5%;
  padding: 1.5% 3.5%;
  width: 27%;
  font-weight: 600;
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px;
  font-size: 14px;
}

#Navlogin:hover,
#Navsignup:hover,
#Navmember:hover,
#NavBlog:hover {
  color: #1a293c;
  background-color: #d8dfe9;
}

/* Navbar for small screen */
@media (max-width: 767px) {
  #navLogo {
    margin-right: 50%;
  }
  #Navlogin,
  #Navsignup,
  #Navmember,
  #NavBlog {
    margin: 0 2%; /* Adjust margin for spacing */
    padding: 2% 3%; /* Adjust padding for the links */
    font-size: 14px;
    width: 18vw;
  }

  .middle {
    display: none; /* Hide the middle links on small screens */
  }

  .right {
    justify-content: space-evenly;
  }
}

/* HomeText.css */

.HomeText {
  display: flex;
  align-items: center;
  background-color: #1a293c;
  justify-content: space-between;
  border-bottom-left-radius: 3%;
  border-bottom-right-radius: 3%;
}

.HT-left-half {
  flex: 1;
  color: #ffffff;
  align-items: center;
  margin-bottom: 1.5%;
}

#HT-mainText {
  font-family: "Jaldi", "Roboto";
  font-weight: 600;
  margin-bottom: 1%;
  margin-left: 4vw;
}

#HT-secondText {
  font-family: "Jaldi", "Roboto";
  margin-left: 4%;
}

#Get-Started {
  font-family: "Jaldi", "Roboto";
  margin-left: 4%;
  padding: 0.4% 2%;
  border-radius: 12px;
  background-color: #ffffff;
  color: #000000;
  width: 10%;
  border: none;
  font-size: 14px;
}

#Get-Started a {
  color: #000000;
  font-weight: 600;
  text-decoration: none;
}

#Get-Started a:hover {
  text-decoration: underline;
  color: #1a293c;
}

#Blog {
  font-family: "Jaldi", "Roboto";
  margin-left: 6%;
  padding: 0.4% 2%;
  border-radius: 12px;
  background-color: #ffffff;
  color: #000000;
  width: 10%;
  border: none;
  font-size: 14px;
}

#Blog a {
  color: #000000;
  font-weight: 600;
  text-decoration: none;
}

#Blog a:hover {
  text-decoration: underline;
  color: #1a293c;
}

#HT-Home1 {
  width: 50%;
  margin-left: 20%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
/* For small screens */
@media (max-width: 767px) {
  .HomeText {
    flex-direction: column;
    padding-bottom: 5vw;
  }

  .HT-left-half {
    flex: 1;
    text-align: center;
    margin: 2.5%;
    margin-bottom: 0;
  }

  #HT-Home1 {
    width: 80%;
    margin-left: 0%;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  #HT-mainText {
    font-size: 6.5vw;
  }

  #HT-secondText {
    font-size: 4vw;
    margin-right: 3.75%;
    margin-bottom: 0;
    display: none;
  }

  #Get-Started {
    display: none;
  }

  #Blog {
    font-size: 14px;
    padding: 1.5% 5.5%;
    margin-right: 4%;
    width: 25%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #Blog,
  #Get-Started {
    width: 13%;
  }

  #Navmember,
  #Navlogin {
    width: 37%;
  }
}

/*AppLinks*/
.app-link-section {
  font-family: 'Jaldi', 'Roboto';
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

#app-link-title {
  font-family: 'Jaldi', 'Roboto';
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.app-box {
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

#app-image-owner {
  width: 90%;
  border-radius: 10px;
  margin-bottom: 10px;
}

#app-image-member {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.app-logo {
  width: 50px;
  margin-bottom: 10px;
  border-radius: 20%;
}

.app-name {
  font-size: 18px;
  font-weight: 600;
}

.app-desc {
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
}

.store-links {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.store-icon {
  width: 120px;
}

.store-icon-gp {
  width: 110px;
  margin-top: 4px;
}
@media (min-width: 768px) {
  .app-container {
    flex-direction: row;
    justify-content: center;
  }
}
/* Service Card */

.serviceCard-main {
  width: 22%;
  height: 100%;
  margin: 5%;
  background-color: #1a293c;
  border-radius: 12px;
  color: #ffffff;
  max-height: 50%;
}

.serviceCard-top {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: auto;
}

.serviceCard-middle {
  display: flex;
  font-family: "Jaldi", "Roboto";
  align-items: center;
  justify-content: center;
  margin: 4.5%;
  font-size: 1.25vw;
  font-weight: 600;
}

.serviceCard-bottom {
  text-align: center;
  font-family: "Jaldi", "Roboto";
  margin: 4%;
}

#serviceCard-img {
  width: 30%;
  margin-top: 10%;
}

#serviceCard-description {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Service Cards for Small Screens */
@media (max-width: 767px) {
  .serviceCard-main {
    width: 40%; /* Adjust the width for small screens */
    max-height: none; /* Reset max-height for small screens */
  }

  #serviceCard-img {
    width: 20%; /* Adjust image size for small screens */
  }

  .serviceCard-middle {
    font-size: 4vw; /* Adjust font size for small screens */
    margin: 4%;
  }

  #serviceCard-description {
    display: none;
  }
}

/* Servies */

.ServiceCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 70%;
  max-width: 100%;
  margin: 0 auto;
}

.serviceCard-header {
  margin-top: 3.125%;
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 2vw;
  font-weight: 600;
}

.serviceCard-footer1 {
  margin-bottom: 3%;
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 2vw;
  font-weight: 600;
}

.serviceCard-footer2 {
  background-color: #1a293c;
  color: #ffffff;
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 2vw;
  margin-bottom: 0.1%;
  padding-top: 2%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ServiceCards {
    width: 90%;
  }

  .serviceCard-header {
    font-size: 4vw;
  }

  .serviceCard-middle {
    font-size: 2.25vw;
  }

  .serviceCard-footer1 {
    font-size: 4vw;
  }
}

/* Services for Small Screens */
@media (max-width: 767px) {
  .ServiceCards {
    /* Stack service cards vertically on small screens */
    width: 100%;
  }

  .serviceCard-header,
  .serviceCard-footer1,
  .serviceCard-footer2 {
    font-size: 4.5vw; /* Adjust font size for small screens */
  }

  .serviceCard-footer2 {
    width: 99.5%;
    margin-bottom: 0.54vw;
  }
}

/* PricingCard */
.pricingCard-main {
  min-height: 51vh;
  max-width: 14vw;
  font-family: "Jaldi", "sans-serif";
  background-color: #ffffff;
  border-radius: 2%;
  border: 1px solid #000000;
}

.pricingCard-top {
  text-align: center;
  margin-bottom: 1.25%;
}

#pricingCard-logo {
  margin-top: 1.25%;
}

#pricingCard-title {
  font-size: 1.3vw;
  font-weight: 500;
}

#pricingCard-price {
  font-size: 1.4vw;
  font-weight: 600;
}

.pricingCard-description {
  margin-bottom: 0.625%;
  margin-left: 3%;
}

@media (max-width: 767px) {
  .pricing-bottom {
    flex-direction: column;
    background: #1a293c !important;
  }
  #pricingCard-title {
    font-size: 4.5vw;
  }

  #pricingCard-price {
    font-size: 5vw;
  }
  .pricingCard-main {
    display: flex;
    flex-wrap: wrap;
    min-height: 34vh;
    min-width: 70vw;
    margin-bottom: 6vw;
  }

  .pricingCard-top {
    margin-left: 24vw;
  }
}

/* Pricing */

.pricing-top {
  text-align: center;
  background-color: #1a293c;
  color: #ffffff;
  padding-bottom: 1.8vw;
}

#pricing-top-title {
  font-family: "Jaldi";
  text-align: center;
  margin-top: 5%;
  margin-bottom: 0.625%;
  font-weight: 600;
  padding-top: 1.25%;
  font-size: 2vw;
}

#pricing-top-freetrial {
  font-family: "Jaldi";
  font-size: 2vw;
  font-weight: 400;
}

.pricing-bottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(
    to top,
    #ffffff 0%,
    #ffffff 50%,
    #1a293c 50%,
    #1a293c 100%
  );
}

/* ProfileCard */
.profileCard-main {
  width: 22%;
  height: 100%;
  margin: 5%;
  background-color: #1a293c;
  border-radius: 12px;
  color: #ffffff;
  max-height: 50%;
}

.profileCard-top {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: auto;
}

.profileCard-middle {
  display: flex;
  font-family: "Jaldi", "Roboto";
  align-items: center;
  justify-content: center;
  margin: 4.5%;
  font-size: 1.3vw;
  font-weight: 600;
}

.profileCard-bottom {
  text-align: center;
  font-family: "Jaldi", "Roboto";
  margin: 4.5%;
}

#profileCard-img {
  width: 8vw;
  height: 8vw;
  margin-top: 10%;
  border-radius: 50%;
}

#profileCard-description {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Profile Card for Small Screen */

@media (max-width: 767px) {
  #pricing-top-title {
    font-size: 5vw;
  }

  #pricing-top-freetrial {
    font-size: 5vw;
  }

  .profileCard-main {
    width: 90%;
    margin: 5% auto; /* Center the card horizontally */
  }

  #profileCard-img {
    width: 30vw;
    height: 30vw;
  }

  .profileCard-middle {
    font-size: 5vw;
    margin: 1vw;
  }

  #profileCard-description {
    font-size: 4vw;
    margin: 3vw;
  }
}

/* About Us */

#iframe {
  margin-top: 7%;
  margin-left: 29%;
  width: 40vw;
  height: 50vh;
}
.AboutUs-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  font-weight: 500;
}

#AboutUs-h1 {
  font-family: "Jaldi", "Roboto";
  text-align: center;
  font-size: 2vw;
  font-weight: 600;
  margin-top: 3.8%;
  margin-bottom: 0;
  margin-right: 4.28%;
}

.AboutUs-Cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 70vw;
  margin: 0 auto;
}

.AboutUs-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.9%;
}

.AboutUs-visionAim {
  font-family: "Jaldi", "Roboto";
  display: flex;
  font-size: 2.2vw;
  font-weight: 600;
}

.AboutUs-para {
  background-color: #1a293c;
  color: #ffffff;
  border-radius: 5%;
  display: flex;
  font-family: "Jaldi", "Roboto";
  font-size: 1.25vw;
  margin-left: 20%;
  padding: 2% 3%;
}

.AboutUs-para1 {
  background-color: #1a293c;
  color: #ffffff;
  border-radius: 5%;
  display: flex;
  font-family: "Jaldi", "Roboto";
  font-size: 1.25vw;
  margin-left: 22%;
  padding: 2% 3%;
}

/* AboutUs for Small Screens */
@media (max-width: 767px) {
  .AboutUs-Cards {
    flex-direction: column; /* Stack profile cards vertically on small screens */
  }

  #iframe {
    margin-top: 10%;
    margin-left: 2.5%;
    width: 95vw;
    height: 27vh;
  }

  #AboutUs-h1 {
    font-size: 6vw;
    margin-top: 30px;
    margin-left: 40px;
  }

  .AboutUs-bottom {
    display: flex;
    flex-direction: column;
  }

  .AboutUs-visionAim {
    font-size: 6vw;
  }

  .AboutUs-para,
  .AboutUs-para1 {
    font-size: 4vw;
    margin-left: 1%;
  }
}

/* Connect */

.Connect-top {
  text-align: center;
  background-color: #1a293c;
  color: #ffffff;
  font-family: "Jaldi", "Roboto";
  margin-top: 3.5vw;
  padding-top: 2%;
}

#Connect-topSpan {
  margin-top: 1vw;
  font-size: 2vw;
  font-weight: 600;
}

.Connect-middle {
  display: flex;
  align-items: center;
  background-color: #1a293c;
}

#Connect-form {
  font-family: "Jaldi", "Roboto";
  margin-left: 4vw;
  color: #ffffff;
}

.Connect-middle textarea {
  resize: none;
}

#Connect-image {
  width: 50%;
  margin-left: 25%;
  margin-top: 2.8vw;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

#Connect-nameInput {
  margin-left: 1vw;
  padding: 0.4vw 1.5vw;
  border-radius: 15px;
  margin-bottom: 1vw;
}

#Connect-emailInput {
  margin-left: 1vw;
  padding: 0.4vw 1.5vw;
  border-radius: 15px;
}

#Connect-query {
  margin-top: 3vw;
}

#Connect-queryInput {
  margin-top: 1.5vw;
  margin-left: 1.2vw;
}

#Connect-button {
  padding: 0.65vw 0.1vw;
  background-color: #ffffff;
  color: #000000;
  font-family: "Jaldi", "Roboto";
  width: 40%;
  margin-left: 6vw;
  border-radius: 20px;
}

#Connect-button:hover {
  background-color: rgb(199, 219, 236);
}

.Connect-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#Connect-bottomSpan {
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 2vw;
  margin: 1.1vw;
  font-weight: 600;
}

.Connect-bottomIcons {
  margin-top: 1vw;
  margin-bottom: 1vw;
  margin-left: 22.2vw;
}

.Connect-bottomIcons a {
  padding: 1vw;
}

#Connect-facebook,
#Connect-instagram,
#Connect-linkedin,
#Connect-twitter {
  width: 7%;
}

/* Connect for Small Screens */
@media (max-width: 767px) {
  .Connect-top {
    margin-top: 4vw; /* Adjust margin for small screens */
  }

  #Connect-topSpan {
    margin-top: 2vw;
    font-size: 6vw;
  }

  .Connect-middle {
    flex-direction: column; /* Stack the form and image vertically on small screens */
    align-items: center;
  }

  #Connect-form {
    margin: 2vw; /* Reset left margin */
  }

  #Connect-image {
    display: none;
    width: 80%; /* Adjust image size for small screens */
    margin-left: 0; /* Reset left margin */
    margin-top: 2vw; /* Adjust margin for small screens */
    border-top-left-radius: 0; /* Reset border radius */
    border-top-right-radius: 0; /* Reset border radius */
  }

  #Connect-button {
    width: 50%; /* Make the button full width on small screens */
    margin-left: 20%;
  }

  .Connect-bottom {
    margin-top: 2vw;
  }

  #Connect-bottomSpan {
    font-size: 6vw;
  }

  .Connect-bottomIcons {
    margin-left: 33.2vw;
  }

  .Connect-bottomIcons a {
    padding: 2vw;
  }

  #connect-nameInput,
  #connect-emailInput {
    width: 100%;
    margin-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .serviceCard-main {
    width: 23%;
  }

  #navLogo {
    width: 40%;
  }
  .pricingCard-main {
    max-width: 20vw;
  }

  #pricingCard-title {
    font-size: 2.3vw;
  }

  #pricing-top-title {
    font-size: 3vw;
  }

  #pricing-top-freetrial {
    font-size: 3vw;
  }

  #pricingCard-price {
    font-size: 2.4vw;
  }
  #AboutUs-h1 {
    font-size: 3vw;
  }

  .profileCard-main {
    width: 35%;
  }

  .profileCard-middle {
    font-size: 2.3vw;
  }

  .AboutUs-para {
    font-size: 2.25vw;
  }

  .AboutUs-para1 {
    font-size: 2.25vw;
  }

  .AboutUs-visionAim {
    font-size: 3.2vw;
  }

  #Connect-image {
    margin-left: 12%;
  }

  #Connect-topSpan {
    font-size: 3vw;
  }

  #Connect-bottomSpan {
    font-size: 3vw;
  }

  #Connect-button {
    width: 60%;
  }
}
