.confirmation-dialog-overlay{
    font-family: 'Jaldi', 'sans-serif';
    max-height: 10vw;
    margin-bottom: 1vw;
}

.cancel-button, .confirm-button{
    background-color: #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.cancel-button:hover, .confirm-button:hover{
    background-color: #0056b3;
}

.cancel-button{
    margin-right: 2vw;
}