* {
    margin: 0;
    padding: 0;
  }
  
.MemberPaymentCard-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Jaldi", "sans-serif";
    padding: 1.5vw 1.2vw;
    background-color: #3D2952;
    color: #ffffff;
    max-width: 95%;
    border-radius: 8px;
    margin-bottom: 15px;
}

#member-PaymentInformation-header-title{
    font-family: "Jaldi", "sans-serif";
    font-weight: 600;
    margin-left: 1%;
}

.member-PaymentInformation-container{
    max-height: 62vh;
    overflow-y: auto;
    background-color: #dbdcdd;
    margin: 10px;
    padding-left: 10px;
}

.member-PaymentInformation-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  margin-left: 1%;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
  font-weight: 600;
}

#member-PaymentInformation-time{
  margin-left: 4%;
}

#member-PaymentInformation-date{
  margin-left: 0%;
}

#member-PaymentInformation-mode{
  margin-left: 2%;
}

.member-PaymentInformation-footer{
  margin-top: 2%;
  margin-left: 1%;
}

#member-PaymentInformation-footer-h2{
  font-family: 'Jaldi', 'sans-serif';
  font-weight: 600;
}

#member-PaymentInformation-footer-main{
  font-family: 'Jaldi', 'sans-serif';
}

@media screen and (max-width: 767px){

  .member-PaymentInformation-container{
    max-height: 34vh;
      width: 89vw;
      margin-left: 8%;
  }

  .MemberPaymentCard-main{
    padding: 4vw 1.5vw;
  }

  #member-PaymentInformation-header-title{
    margin-top: 4vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
  }
}