@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");

.login-container {
  display: flex;
  height: 100vh;
}

.login-left-half {
  flex: 40%;
  height: 94.3%;
  background-color: #1a293c;
  text-align: left;
  font-size: 3.15vh;
  font-family: "Jaldi", "sans-serif";
  color: #ffffff;
  padding: 2.9vh;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.logo {
  margin-top: 1.57vw;
  margin-right: 1.57vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#login-mainLogo {
  text-align: end;
  width: 20%;
  height: 20%;
  border-radius: 20%;
  margin-left: 80%;
}

.login-right-half {
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.57vw;
}

#login-right-half-h2 {
  font-family: "Jaldi", sans-serif;
  font-size: 2vw;
  text-align: left;
  margin-right: 9.8vw;
}

.login-b-text {
  margin-top: 0;
  font-size: 1.5vw;
  font-weight: lighter;
}

#login-Email,
#login-Password {
  text-align: left;
  font-weight: 600;
}

.login-form {
  width: 100%;
  max-width: 50%;
  margin-top: 1.5vw;
  margin-right: 22%;
  font-family: "Jaldi";
}

.login-label {
  display: block;
  margin-bottom: 0.7vw;
}

#login-emailInput,
#login-passwordInput {
  width: 60%;
  padding: 0.8vw 0.8vw;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-right: 6.3%;
}

#login-passwordInput {
  margin-bottom: 2vw;
}

.password-input {
  position: relative;
}

#login-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 0.6vw 0.8vw;
  border-radius: 2.1vw;
  cursor: pointer;
  width: 67%;
  margin-bottom: 3vh;
}

#login-submit:hover {
  background-color: #0056b3;
}
.login-right-half-top {
  margin-top: 0.52vw;
  margin-bottom: 1.2vw;
}
p {
  margin-top: 0.52vw;
  margin-bottom: 1.05vw;
}

.login-signup-link {
  margin-top: 1.57vw;
  margin-right: 40%;
  font-family: 'Jaldi','sans-serif';
}
#login-googleSignin {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ede7e7;
  color: #000000;
  border: none;
  padding: 0.6vw 0.8vw;
  border-radius: 20px;
  cursor: pointer;
  width: 68%;
}
#login-googleSignin:hover {
  background-color: #dde3ed;
}
#googleLogo {
  width: 8%;
  align-items: flex-start;
  margin-right: auto;
  text-align: center;
}

.login-signup-link a {
  color: #007bff;
  text-decoration: none;
}

.login-signup-link a:hover {
  text-decoration: underline;
}

#login-forgot-pswd {
  text-align: left;
  color: rgb(233, 25, 25);
  margin-right: 18vh;
  margin-bottom: 1vh;
}

#login-Or {
  color: rgb(124, 123, 123);
  margin-bottom: 2vh;
  margin-left: 8vw;
}

#Google-text {
  margin-right: auto;
}

.login-slideshow-image {
  width: 55%;
  transition: opacity 0.5s ease-in-out;
  border-radius: 10px;
  margin-left: 4vw;
}

#login-left-half-h1,
#login-Where-we {
  margin-left: 4vw;
}

#login-Where-we {
  margin-top: 8vw;
}
#login-left-half-h1 {
  font-size: 4.1vw;
  margin-top: 7px;
  margin-bottom: 60px;
}

#login-for {
  font-size: 2vw;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 767px) {
  .login-container {
    flex-direction: column;
    height: auto;
  }

  .login-left-half {
    flex: 40%;
    padding: 0;
    border-radius: 20px;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  #login-mainLogo {
    width: 33%;
    height: auto;
    margin-left: 60%;
    display: none;
  }

  #login-left-half-h1 {
    font-size: 10vw;
    margin-left: 0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }
  #login-Where-we {
    display: none;
  }

  #login-for {
    font-size: 6vw;
  }

  .login-slideshow-image {
    display: none;
  }

  .login-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  #login-right-half-h2 {
    font-size: 7vw;
    margin-right: 0;
    margin-left: 15vw;
  }

  .login-b-text {
    font-size: 5vw;
  }

  .login-form {
    margin-right: 33%;
    max-width: 72%;
  }

  #login-emailInput,
  #login-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 4.0vw;
    padding: 1.9vw 0.8vw
  }
  .login-label{
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 5.0vw;
  }
  #login-Or {
    margin-left: 48vw;
    font-size: 5vw;
    margin-bottom: 4vw;
    margin-top: 4vw;
  }

  .login-form-group {
    margin-bottom: 10px;
  }

  #login-passwordInput {
    margin-bottom: 4vw;
  }

  #login-submit,
  #login-googleSignin {
    width: 100%;
    margin-right: 0;
    padding: 2.5vw;
    margin-left: 70px;
  }

  #login-submit {
    border-radius: 2.7vw;
    font-size: 4.0vw;
  }

  .login-signup-link {
    margin-top: 3.5vw;
    margin-left: 2.0vw;
    font-size: 5.0vw;
    margin-right: 0;
  }

  #login-forgot-pswd {
    text-align: center;
    margin-right: 0;
    margin-left: 18vw;
    font-size: 5.0vw;
    margin-bottom: 1vw;
  }
  .login-signup-link a:hover {
    text-decoration: underline;
  }

  #login-submit:hover {
    background-color: #0056b3;
  }

  #login-googleSignin:hover {
    background-color: #dde3ed;
  }

  #Google-text {
    font-size: 4.0vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .login-container {
    flex-direction: column;
    height: auto;
  }

  .login-left-half {
    flex: 40%;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
  }

  #login-mainLogo {
    width: 33%;
    height: auto;
    margin-left: 60%;
  }

  #login-left-half-h1 {
    font-size: 10vw;
    margin-left: 0;
    text-align: center;
  }
  #login-Where-we {
    margin-left: 9vw;
  }

  #login-for {
    font-size: 6vw;
  }

  .login-slideshow-image {
    width: 80%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .login-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  #login-right-half-h2 {
    font-size: 7vw;
    margin-right: 0;
    margin-left: 15vw;
  }

  .login-b-text {
    font-size: 5vw;
  }

  .login-form {
    margin-right: 38%;
  }

  .login-label,
  #login-emailInput,
  #login-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 3.5vw;
  }

  #login-Or {
    margin-left: 31vw;
    font-size: 3vw;
    margin-bottom: 4vw;
    margin-top: 4vw;
  }

  .login-form-group {
    margin-bottom: 10px;
  }

  #login-passwordInput {
    margin-bottom: 4vw;
  }

  #login-submit,
  #login-googleSignin {
    width: 100%;
    margin-right: 0;
    padding: 1.5vw;
    margin-left: 70px;
  }

  #login-submit {
    border-radius: 2.7vw;
    font-size: 2.5vw;
  }

  .login-signup-link {
    margin-top: 3.5vw;
    margin-right: 16vw;
    font-size: 3vw;
    margin-bottom: 3.5vw;
  }

  #login-forgot-pswd {
    text-align: center;
    margin-right: 0;
    margin-left: 9vw;
    font-size: 3vw;
    margin-bottom: 1vw;
  }
  .login-signup-link a:hover {
    text-decoration: underline;
  }

  #login-submit:hover {
    background-color: #0056b3;
  }

  #login-googleSignin:hover {
    background-color: #dde3ed;
  }

  #Google-text {
    font-size: 2.5vw;
  }
}
