@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");

.memberlogin-container {
  display: flex;
  height: 100vh;
}

.member-left-half {
  flex: 40%;
  height: 94.3%;
  background-color: #3d2952;
  text-align: left;
  font-size: 3.15vw;
  font-family: "Jaldi", "sans-serif";
  color: #ffffff;
  padding: 2.9vh;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.memberlogo {
  margin-top: 1.57vw;
  margin-right: 1.57vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#mb-mainLogo {
  text-align: end;
  width: 20%;
  height: 20%;
  border-radius: 20%;
  margin-left: 80%;
}

.member-right-half {
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.57vw;
}

.memberlogin-h2 {
  font-family: "Jaldi", sans-serif;
  font-size: 2vw;
  text-align: left;
  margin-right: 9.8vw;
}

.memberlogin-h3 {
  margin-top: 0;
  font-size: 1.5vw;
  font-weight: lighter;
}

#mb-login-Email,
#mb-login-Password {
  text-align: left;
}

.mb-form {
  width: 100%;
  max-width: 50%;
  margin-top: 1.5vw;
  margin-right: 22%;
  font-family: "Jaldi";
}

.mb-login-form-group {
  margin-bottom: 1.57vw;
}

.mb-label {
  display: block;
  margin-bottom: 0.7vw;
  font-weight: 600;
}

#mb-login-emailInput,
#mb-login-passwordInput {
  width: 60%;
  padding: 0.8vw 0.8vw;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-right: 6.3%;
}

.mb-login-password-input {
  margin-bottom: 2vw;
}

#mb-login-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.6vw 0.8vw;
  border-radius: 2.1vw;
  cursor: pointer;
  width: 67%;
}

#mb-login-submit:hover {
  background-color: #0056b3;
}

.mb-right-half-top {
  margin-top: 0.52vw;
  margin-bottom: 1.2vw;
}

p {
  margin-top: 0.52vw;
  margin-bottom: 1.05vw;
}

.mb-login-signup-link {
  margin-top: 1.57vw;
  margin-right: 40%;
}

#mb-login-googleSignin {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ede7e7;
  color: #000000;
  border: none;
  padding: 0.6vw 0.8vw;
  border-radius: 20px;
  cursor: pointer;
  width: 68%;
}

#mb-login-googleSignin:hover {
  background-color: #dde3ed;
}

#mb-login-googleLogo {
  width: 8%;
  align-items: flex-start;
  margin-right: auto;
  text-align: center;
}

.mb-login-signup-link a {
  color: #007bff;
  text-decoration: none;
}

.mb-login-signup-link a:hover {
  text-decoration: underline;
}

#mb-login-forgot-pswd {
  text-align: left;
  color: rgb(233, 25, 25);
  margin-right: 18vh;
  margin-bottom: 1vh;
}

#mb-login-Or {
  color: rgb(124, 123, 123);
  margin-bottom: 2vh;
  margin-left: 8vw;
}

#mb-login-Google-text {
  margin-right: auto;
}

.mb-slideshow-image {
  width: 55%;
  transition: opacity 0.5s ease-in-out;
  border-radius: 10px;
  margin-left: 4vw;
}

#mb-left-half-h1,
#mb-Where-we {
  margin-left: 4vw;
}

#mb-Where-we {
  margin-top: 8vw;
}

#mb-left-half-h1 {
  font-size: 4.1vw;
  margin-top: 7px;
  margin-bottom: 60px;
}

#mb-for {
  font-size: 2vw;
}

/* Media Query for Mobile Devices */

@media screen and (max-width: 767px) {
  .memberlogin-container {
    flex-direction: column;
    height: auto;
  }

  .member-left-half {
    flex: 40%;
    padding: 0;
    border-radius: 20px;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 20px;
  }

  #mb-mainLogo {
    width: 23%;
    height: auto;
    margin-left: 64%;
  }

  #mb-left-half-h1 {
    font-size: 10vw;
    margin-left: 0;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  #mb-Where-we {
    display: none;
  }

  #mb-for {
    font-size: 6vw;
  }

  .mb-slideshow-image {
    display: none;
  }

  .member-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  .memberlogin-h2 {
    font-size: 7vw;
    margin-right: 0;
    margin-left: 15vw;
  }

  .memberlogin-h3 {
    font-size: 5vw;
  }

  .mb-form {
    margin-right: 33%;
    max-width: 72%;
  }

  .mb-label {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 5.0vw;
  }

  #mb-login-emailInput,
  #mb-login-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 4.0vw;
    padding: 1.9vw 0.8vw;
  }

  #mb-login-Or {
    margin-left: 48vw;
    font-size: 5vw;
    margin-bottom: 4vw;
    /* margin-top: 4vw; */
  }

  .mb-login-form-group {
    margin-bottom: 10px;
  }

  #mb-login-passwordInput {
    margin-bottom: 4vw;
  }

  #mb-login-submit,
  #mb-login-googleSignin {
    width: 100%;
    margin-right: 0;
    padding: 2.5vw;
    margin-left: 70px;
  }

  #mb-login-submit {
    border-radius: 2.7vw;
    font-size: 4vw;
    margin-bottom: 1vh;
  }

  .mb-login-signup-link {
    margin-top: 3.5vw;
    margin-right: 0;
    margin-left: 2.0vw;
    font-size: 5vw;
  }

  #mb-login-forgot-pswd {
    text-align: center;
    margin-right: 0;
    margin-left: 18vw;
    font-size: 4.0vw;
    margin-bottom: 1vw;
  }

  .mb-login-signup-link a:hover {
    text-decoration: underline;
  }

  #mb-login-submit:hover {
    background-color: #0056b3;
  }

  #mb-login-googleSignin:hover {
    background-color: #dde3ed;
  }

  #mb-login-Google-text {
    font-size: 4.0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .memberlogin-container {
    flex-direction: column;
    height: auto;
  }

  .member-left-half {
    flex: 40%;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
  }

  #mb-mainLogo {
    width: 33%;
    height: auto;
    margin-left: 60%;
  }

  #mb-left-half-h1 {
    font-size: 10vw;
    margin-left: 0;
    text-align: center;
  }

  #mb-Where-we {
    margin-left: 9vw;
  }

  #mb-for {
    font-size: 6vw;
  }

  .mb-slideshow-image {
    width: 80%;
    margin-left: 0;
    margin-bottom: 20px;
    border-radius: 18px;
  }

  .member-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  .memberlogin-h2 {
    font-size: 7vw;
    margin-right: 0;
    margin-left: 15vw;
  }

  .memberlogin-h3 {
    font-size: 5vw;
  }

  .mb-form {
    margin-right: 38%;
  }

  .mb-label,
  #mb-login-emailInput,
  #mb-login-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 3.5vw;
  }

  #mb-login-Or {
    margin-left: 31vw;
    font-size: 3vw;
    margin-bottom: 4vw;
    margin-top: 4vw;
  }

  .mb-login-form-group {
    margin-bottom: 10px;
  }

  #mb-login-passwordInput {
    margin-bottom: 4vw;
  }

  #mb-login-submit,
  #mb-login-googleSignin {
    width: 100%;
    margin-right: 0;
    padding: 1.5vw;
    margin-left: 70px;
  }

  #mb-login-submit {
    border-radius: 2.7vw;
    font-size: 2.5vw;
  }

  .mb-login-signup-link {
    margin-top: 3.5vw;
    margin-right: 16vw;
    font-size: 3vw;
    margin-bottom: 3.5vw;
  }

  #mb-login-forgot-pswd {
    text-align: center;
    margin-right: 0;
    margin-left: 9vw;
    font-size: 3vw;
    margin-bottom: 1vw;
  }

  .mb-login-signup-link a:hover {
    text-decoration: underline;
  }

  #mb-login-submit:hover {
    background-color: #0056b3;
  }

  #mb-login-googleSignin:hover {
    background-color: #dde3ed;
  }

  #mb-login-Google-text {
    font-size: 2.5vw;
  }
}