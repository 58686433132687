@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jaldi:wght@400;700&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
}

/* Navbar.css */

.fixed-memberlogin-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #3d2952;
  font-family: "Jaldi";
  color: white;
  text-align: center;
  padding-bottom: 0;
  z-index: 100; /* Ensure it's above other content */
}
.memberlogin-navbar {
  background-color: #3d2952;
  font-family: "Jaldi";
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-bottom: 0;
}

.memberlogin-navbar a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.memberlogin-navbar a:hover {
  text-decoration: underline;
  color: #df9be3;
}

.memberlogin-left {
  flex: 1;
  justify-content: flex-start;
}

.memberlogin-middle {
  flex: 3;
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
}

.memberlogin-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

#memberlogin-navLogo {
  width: 30%;
  margin: 10px;
  margin-right: 95px;
  border-radius: 15px;
}

#memberNavlogin,
#memberNavsignup {
  margin: 0 15px;
  padding: 5px 18px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px;
  width:27%;
  font-weight: 600;
}

#memberNavlogin:hover,
#memberNavsignup:hover {
  background-color: #df9be3;
}

/* Navbar for small screen */

@media (max-width: 767px) {
  .memberlogin-navbar {
    padding: 10px; /* Add some padding for spacing on small screens */
    max-height: 40%;
  }

  .memberlogin-middle {
    display: none; /* Hide the middle links on small screens */
  }

  .memberlogin-left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  #memberlogin-navLogo {
    width: 60%;
    margin: 0;
    border-radius: 15px;
    max-width: 35%;
  }

  .memberlogin-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  #memberNavlogin,
  #memberNavsignup {
    font-size: 16px;
    padding: 1.5% 3%; /* Adjust padding for the links */
    width: 20vw;
  }

  #memberNavlogin {
    margin-left: 18%;
  }
}

/* HomeText.css */

.memberHomeText {
  display: flex;
  align-items: center;
  background-color: #3d2952;
  justify-content: space-between;
  border-bottom-left-radius: 3%;
  border-bottom-right-radius: 3%;
}

.memberHT-left-half {
  flex: 1;
  color: #ffffff;
  align-items: center;
}

.memberHT-right-half {
  flex: 1;
  align-items: center;
}

#memberHT-mainText {
  font-family: "Jaldi", "Roboto";
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 4vw;
}

#memberHT-secondText {
  font-family: "Jaldi", "Roboto";
  margin-left: 47px;
}

#memberGet-Started {
  font-family: "Jaldi", "Roboto";
  margin-left: 60px;
  padding: 10px;
  border-radius: 12px;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  margin-bottom: 30px;
}

#memberGet-Started a {
  color: #000000;
  font-weight: 600;
  text-decoration: none;
}

#memberGet-Started a:hover {
  text-decoration: underline;
  color: #1a293c;
}

#memberHT-Home1 {
  width: 48%;
  margin-left: 20%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
/* For small screens */
@media (max-width: 767px) {
  .memberHomeText {
    flex-direction: column;
  }

  .memberHT-left-half
  {  
    flex: 1;
    text-align: center;
    padding-bottom: 2vw;
  }

  #memberHT-Home1 {
    display: none;
  }

  #memberHT-mainText {
    font-size: 30px;
  }

  #memberHT-secondText {
    display: none;
    font-size: 16px;
    margin-right: 45px;
    margin-bottom: 0;
  }

  #memberGet-Started {
    font-size: 18px;
    padding: 8px;
    margin-right: 45px;
  }
}

/* Service Card */

.member-serviceCard-main {
  width: 22%;
  height: 100%;
  margin: 5%;
  background-color: #3d2952;
  border-radius: 12px;
  color: #ffffff;
  max-height: 50%;
}

.member-serviceCard-top {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: auto;
}

.member-serviceCard-middle {
  display: flex;
  font-family: "Jaldi", "Roboto";
  align-items: center;
  justify-content: center;
  margin: 4.5%;
  font-size: 1.25vw;
  font-weight: 600;
}

.member-serviceCard-bottom {
  text-align: center;
  font-family: "Jaldi", "Roboto";
  margin: 20px;
}

#member-serviceCard-img {
  width: 30%;
  margin-top: 10%;
}

#member-serviceCard-description {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15vh;
}

/* Service Cards for Small Screens */
@media (max-width: 767px) {
  .member-serviceCard-main {
    width: 40%; /* Adjust the width for small screens */
    max-height: none; /* Reset max-height for small screens */
  }

  #member-serviceCard-img {
    width: 20%; /* Adjust image size for small screens */
  }

  .member-serviceCard-middle {
    font-size: 16px; /* Adjust font size for small screens */
    margin: 10px;
  }

  #member-serviceCard-description {
    display: none;
  }
}

/* Servies */

.member-ServiceCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width:70%;
  max-width: 100%;
  margin: 0 auto;
}

.member-serviceCard-header {
  margin-top: 50px;
  font-weight: 600;
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 30px;
}

.member-serviceCard-footer1 {
  margin-bottom: 40px;
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 30px;
  font-weight: 600;
}

.member-serviceCard-footer2 {
  background-color: #3d2952;
  color: #ffffff;
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 30px;
  margin-bottom: 0;
  padding-top: 2%;
}


/* Services for Small Screens */
@media (max-width: 767px) {
  .member-ServiceCards {
    width:100%;
  }

  .member-serviceCard-header,
  .member-serviceCard-footer1,
  .member-serviceCard-footer2 {
    font-size: 23px; /* Adjust font size for small screens */
  }

  .member-serviceCard-footer2 {
    width: 98.8%;
  }

  .member-review-slideshow {
    flex-direction: column; /* Stack reviews vertically on small screens */
    width: 100%;
    max-height: fit-content;
  }

  .member-review {
    margin: 10px;
  }

  .member-reviewer-image {
    width: 100px; /* Adjust image size for small screens */
    height: 100px; /* Adjust image size for small screens */
    margin: 10px;
  }

  .member-review h3 {
    font-size: 18px;
    margin-bottom: 2px;
  }

  .member-review span {
    margin-top: 0;
  }
  .member-review p {
    text-align: left;
  }
}

/* ProfileCard */
.member-profileCard-main {
  width: 22%;
  height: 100%;
  margin: 5%;
  background-color: #3d2952;
  border-radius: 12px;
  color: #ffffff;
  max-height: 50%;
}

.member-profileCard-top {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: auto;
}

.member-profileCard-middle {
  display: flex;
  font-family: "Jaldi", "Roboto";
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 20px;
  font-weight: 600;
}

.member-profileCard-bottom {
  text-align: center;
  font-family: "Jaldi", "Roboto";
  margin: 20px;
}

#member-profileCard-img {
  width: 120px;
  height: 120px;
  margin-top: 10%;
  border-radius: 50%;
}

#member-profileCard-description {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Profile Card for Small Screen */

@media (max-width: 767px) {
  .member-profileCard-main {
    width: 90%;
    margin: 5% auto; /* Center the card horizontally */
  }

  .member-profileCard-middle {
    font-size: 18px;
    margin: 10px;
  }

  #member-profileCard-description {
    margin: 10px;
  }
}

/* About Us */

#member-AboutUs-h1 {
  font-family: "Jaldi", "Roboto";
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 0;
  margin-right: 65px;
}

.member-AboutUs-Cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}

.member-AboutUs-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.member-AboutUs-visionAim {
  font-family: "Jaldi", "Roboto";
  display: flex;
  font-size: 35px;
  font-weight: 600;
}

.member-AboutUs-para {
  background-color: #3d2952;
  color: #ffffff;
  border-radius: 5%;
  display: flex;
  font-family: "Jaldi", "Roboto";
  font-size: 20px;
  margin-left: 20%;
  padding: 2% 3%;
}

.member-AboutUs-para1 {
  background-color: #3d2952;
  color: #ffffff;
  border-radius: 5%;
  display: flex;
  font-family: "Jaldi", "Roboto";
  font-size: 20px;
  margin-left: 22%;
  padding: 2% 3%;
}

/* AboutUs for Small Screens */
@media (max-width: 767px) {
  .member-AboutUs-Cards {
    flex-direction: column; /* Stack profile cards vertically on small screens */
  }

  #member-AboutUs-h1 {
    font-size: 25px;
    margin-top: 60px;
    margin-left: 45px;
  }

  .member-AboutUs-bottom {
    display: flex;
    flex-direction: column;
  }

  .member-AboutUs-visionAim {
    font-size: 25px;
  }

  .member-AboutUs-para,
  .member-AboutUs-para1 {
    font-size: 18px;
    margin-left: 1%;
  }
}

/* Connect */

.member-Connect-top {
  text-align: center;
  background-color: #3d2952;
  color: #ffffff;
  font-family: "Jaldi", "Roboto";
  font-size: 30px;
  margin-top: 50px;
  padding-top: 2%;
}

#member-Connect-topSpan {
  margin-top: 10px;
}

.member-Connect-middle {
  display: flex;
  align-items: center;
  background-color: #3d2952;
}

#member-Connect-form {
  font-family: "Jaldi", "Roboto";
  margin-left: 4vw;
  color: #ffffff;
}

.member-Connect-middle textarea {
  resize: none;
}

#member-Connect-image {
  width: 50%;
  margin-left: 24%;
  margin-top: 40px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

#member-Connect-nameInput {
  margin-left: 16px;
  padding: 4px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
}

#member-Connect-emailInput {
  margin-left: 17px;
  margin-bottom: 10px;
  padding: 4px 15px;
  border-radius: 15px;
}

#member-Connect-queryInput{
  margin-top: 20px;
  margin-left: 12px;
}

#member-Connect-button {
  padding: 10px 1px;
  background-color: #ffffff;
  color: #000000;
  font-family: "Jaldi", "Roboto";
  width: 40%;
  margin-left: 80px;
  border-radius: 20px;
}

#member-Connect-query{
  margin-top: 3vw;
}

#member-Connect-button:hover {
  background-color: rgb(199, 219, 236);
}

.member-Connect-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#member-Connect-bottomSpan {
  text-align: center;
  font-family: "Jaldi", "Roboto";
  font-size: 30px;
  margin: 15px;
  font-weight: 600;
}

.member-Connect-bottomIcons {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.member-Connect-bottomIcons a {
  padding: 15px;
}

/* Connect for Small Screens */
@media (max-width: 767px) {
  .member-Connect-top {
    font-size: 25px; /* Adjust font size for small screens */
    margin-top: 30px; /* Adjust margin for small screens */
  }

  #member-Connect-topSpan {
    margin-top: 20px; /* Adjust margin for small screens */
  }

  .member-Connect-middle {
    flex-direction: column; /* Stack the form and image vertically on small screens */
    align-items: center;
  }

  #member-Connect-form {
    margin: 0; /* Reset left margin */
  }

  #member-Connect-image {
    display: none;
  }

  #member-Connect-button {
    width: 100%; /* Make the button full width on small screens */
    margin-left: 0;
  }

  .member-Connect-bottom {
    margin-top: 20px;
  }

  #member-Connect-bottomSpan {
    font-size: 25px;
  }

  .member-Connect-bottomIcons {
    align-items: center;
  }

  .member-Connect-bottomIcons a {
    padding: 10px;
  }
  

  #member-connect-nameInput,
  #member-connect-emailInput {
    width: 100%;
    margin-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  #memberlogin-navLogo{
    width: 50%;
  }
  #memberHT-secondText {
    margin-left: 32px;
  }

  #memberHT-Home1{
    width: 70%;
  }

  .memberHomeText {
    padding-bottom: 2vw;
  }

  .member-ServiceCards{
    width: 100vw;
  }

  .member-serviceCard-main{
    width: 39%;
  }

  .member-profileCard-main{
    width: 35%;
  }

  #member-Connect-image{
    margin-left: 10%;
  }
}