@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");

.member-signup-container {
  display: flex;
  height: 100vh;
}

.member-signup-left-half {
  flex: 40%;
  height: 94.3%;
  background-color: #3d2952;
  text-align: left;
  font-size: 30px;
  font-family: "Jaldi", "sans-serif";
  color: #ffffff;
  padding: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.member-signup-logo {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#member-signup-mainLogo {
  margin-left: 80%;
  text-align: end;
  width: 20%;
  height: 20%;
  border-radius: 20%;
}

.member-signup-right-half {
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

#member-signup-right-half-h2 {
  font-family: "Jaldi", "sans-serif";
  font-size: 2vw;
  text-align: left;
  margin-right: 9.8vw;
}

.member-signup-b-text {
  margin-top: 0;
  font-size: 1.5vw;
  font-weight: lighter;
}

#member-signup-Email,
#member-signup-Password,
#member-signup-Name {
  text-align: left;
}

.member-signup-form {
  width: 100%;
  max-width: 50%;
  margin-top: 1.5vw;
  margin-right: 22%;
  font-family: "Jaldi", "sans-serif";
}

.member-signup-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

#member-signup-nameInput,
#member-signup-emailInput,
#member-signup-passwordInput {
  width: 60%;
  padding: 0.8vw 0.8vw;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-right: 6.3%;
}

#member-signup-passwordInput {
  margin-bottom: 2vw;
}

.member-password-input {
  position: relative;
}

#member-signup-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.6vw 0.8vw;
  border-radius: 2.1vw;
  cursor: pointer;
  width: 67%;
  margin-bottom: 3vh;
}

#member-signup-button:hover {
  background-color: #0056b3;
}

.member-login-link {
  margin-top: 30px;
  margin-right: 22vw;
}

.member-login-link a {
  color: #007bff;
  text-decoration: none;
}

.member-login-link a:hover {
  text-decoration: underline;
}

.member-signup-slideshow-image {
  width: 55%;
  transition: opacity 0.5s ease-in-out;
  border-radius: 10px;
  margin-left: 4vw;
}

#member-signup-h1,
#member-signup-Where-we {
  margin-left: 4vw;
}

#member-signup-Where-we {
  margin-top: 8vw;
}

#member-signup-h1 {
  font-size: 4.1vw;
  margin-top: 7px;
  margin-bottom: 60px;
}

#member-signup-for {
  font-size: 2vw;
}

#member-signup-mainLogo {
  width: 23%;
  height: auto;
  margin-left: 68%;
}

/* Media Query for Mobile Devices */

@media screen and (max-width: 767px) {
  .member-signup-container {
    flex-direction: column;
    height: auto;
  }

  .member-signup-left-half {
    flex: 40%;
    padding: 0;
    border-radius: 20px;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  #member-signup-h1 {
    font-size: 10vw;
    margin-left: 0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  #member-signup-Where-we {
    display: none;
  }

  #member-signup-for {
    font-size: 6vw;
  }

  .member-signup-slideshow-image {
    display: none;
  }

  .member-signup-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  #member-signup-right-half-h2 {
    font-size: 7vw;
    margin-right: 0;
    margin-left: 15vw;
  }

  .member-signup-b-text {
    font-size: 5vw;
  }

  .member-signup-form {
    margin-right: 33%;
    max-width: 72%;
  }

  .member-signup-label {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 5vw;
    margin-top: 2.5vw;
  }

  #member-signup-nameInput,
  #member-signup-emailInput,
  #member-signup-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 4vw;
    padding: 1.9vw 0.8vw;
  }

  .member-signup-form-group {
    margin-bottom: 10px;
  }

  #member-signup-passwordInput {
    margin-bottom: 4vw;
  }

  #member-signup-button {
    width: 100%;
    margin-right: 0;
    padding: 1.2vw;
    margin-left: 70px;
    border-radius: 2.7vw;
    font-size: 4.0vw;
  }

  .member-login-link {
    margin: 0;
    font-size: 5vw;
  }

  .member-login-link a:hover {
    text-decoration: underline;
  }

  #member-signup-button:hover {
    background-color: #0056b3;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .member-signup-container {
    flex-direction: column;
    height: auto;
  }

  .member-signup-left-half {
    flex: 40%;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
  }

  #member-signup-mainLogo {
    width: 33%;
    height: auto;
    margin-left: 60%;
  }

  #member-signup-h1 {
    font-size: 10vw;
    margin-left: 0;
    text-align: center;
  }

  #member-signup-Where-we {
    margin-left: 9vw;
  }

  #member-signup-for {
    font-size: 6vw;
  }

  .member-signup-slideshow-image {
    width: 80%;
    margin-left: 0;
    margin-bottom: 20px;
    border-radius: 18px;
  }

  .member-signup-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  #member-signup-right-half-h2 {
    font-size: 7vw;
    margin-right: 0;
    margin-left: 15vw;
  }

  .member-signup-b-text {
    font-size: 5vw;
  }

  .member-signup-form {
    margin-right: 38%;
  }

  .member-signup-label,
  #member-signup-nameInput,
  #member-signup-emailInput,
  #member-signup-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
    font-size: 3.5vw;
  }

  #member-signup-Or {
    margin-left: 31vw;
    font-size: 3vw;
    margin-bottom: 4vw;
    margin-top: 4vw;
  }

  .member-signup-form-group {
    margin-bottom: 10px;
  }

  #member-signup-passwordInput {
    margin-bottom: 4vw;
  }

  #member-signup-button {
    width: 100%;
    margin-right: 0;
    padding: 1.5vw;
    margin-left: 70px;
  }

  #member-signup-button {
    border-radius: 2.7vw;
    font-size: 2.5vw;
  }

  .member-login-link {
    margin-top: 3.5vw;
    margin-right: 16vw;
    font-size: 3vw;
    margin-bottom: 3.5vw;
  }

  .member-login-link a:hover {
    text-decoration: underline;
  }

  #member-signup-button:hover {
    background-color: #0056b3;
  }
}