* {
  margin: 0;
  padding: 0;
}

.FeesCollectionCard-main {
  display: flex;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  padding: 25px 20px;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 1.5vw;
}

.FeesCollection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.FeesCollection-header-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
}

.FeesCollection-header-addAmount {
  background-color: #1a293c;
  color: #ffffff;
  padding: 5px 45px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Jaldi", "sans-serif";
  margin-right: 3%;
  font-weight: 600;
}

.FeesCollectionCard-container {
  max-height: 30vw;
  overflow-y: auto;
  background-color: #dbdcdd;
  margin: 1vw;
  padding-left: 2.5vw;
}

.FeesCollection-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  margin-left: 1%;
  margin-bottom: 1%;
  margin-top: 1%;
  font-weight: 600;
}

#FeesCollectionCard-date{
  margin-left: 0.5vw;
}

#FeesCollectionCard-amount{
  margin-left: 3vw;
}

#FeesCollectionCard-time{
  margin-right: 1vw;
}

#FeesCollectionCard-mode{
  margin-right: 5vw;
}

#FeesCollectionCard-main-name{
  width:12vw;
  text-align: left;
}

#FeesCollectionCard-main-amount{
  margin-left: 3.5vw;
  width: 10vw;
  text-align: center;
}

#FeesCollectionCard-main-date{
  margin-left: 8vw;
  width: 10vw;
  text-align: center;
}

#FeesCollectionCard-main-time{
  margin-left: 7vw;
  width: 10vw;
  text-align: center;
}

#FeesCollectionCard-main-mode{
  margin-left: 6.5vw;
  width: 10vw;
  text-align: center;
}

#FeesCollectionCard-main-delete{
  margin-left: 8vw;
}

.FeesCollection-graphHeader {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-top: 2%;
  margin-left: 1%;
  font-size: 28px;
}

.FeesCollection-graphs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
}

.FeesCollection-monthlyCollections {
  background-color: #ffffff;
  height: 40vh;
  width: 40%;
  margin-left: 1%;
  border: 0.7vw solid #d9d9d9;
}

.FeesCollection-upiAndCashDistribution {
  background-color: #ffffff;
  height: 40vh;
  width: 40%;
  margin-right: 1%;
  border: 0.7vw solid #d9d9d9;
}

.AddAmount-form-header {
  font-family: "Jaldi", "sans-serif";
  font-size: 1.8vw;
  margin-left: 44%;
  margin-bottom: 2%;
  font-weight: 600;
}

#AddAmount-form {
  max-width: 37vw;
  margin-left: 35%;
  font-weight: 600;
}

.AddAmount-input-group-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6vw;
}

#AddAmount-name {
  margin-left: 0.5vw;
  padding: 10px 40px;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#AddAmount-nameSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 7vw;
}

.AddAmount-input-group-id {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6vw;
}

#AddAmount-id {
  margin-left: 0.5vw;
  padding: 10px 40px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#AddAmount-idSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 7vw;
}

.AddAmount-input-group-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6vw;
}

#AddAmount-date {
  margin-left: 0px;
  padding: 10px 64px;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
}

#AddAmount-dateSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 7vw;
}

.AddAmount-input-group-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6vw;
}

#AddAmount-amount {
  margin-left: 0.5vw;
  padding: 10px 40px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#AddAmount-amountSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 7vw;
}

.AddAmount-input-group-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6vw;
}

#AddAmount-mode {
  margin-left: 0px;
  padding: 10px 48px;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#AddAmount-modeSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 7vw;
}

#AddAmount-submitButton {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 9px 95px;
  border-radius: 10px;
  margin-left: 4.4vw;
  margin-top: 2.5vw;
  margin-bottom: 1.7vw;
  font-family: "Jaldi", "sans-serif";
  font-size: 1.0vw;
  cursor: pointer;
}

#FeesCollection-noCollection{
  text-align: center;
  font-family: "Jaldi", 'sans-serif';
  font-weight: 600;
  font-size: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  .FeesCollectionCard-container{
    max-height: 47vw;
  }

  .FeesCollectionCard-main{
    padding:10px 10px;
    max-width: 95%;
    margin-right: 2%;
  }

  #FeesCollectionCard-main-name{
    width:18vw;
    text-align: left;
  }
  #FeesCollectionCard-main-amount{
    margin-left: 7vw;
    width: 10vw;
    text-align: center;
  }
  
  #FeesCollectionCard-main-date{
    margin-left: 12vw;
    width: 15vw;
    text-align: center;
  }

  #FeesCollectionCard-time{
    display: none;
  }
  
  #FeesCollectionCard-main-time{
    display: none;
  }
  
  #FeesCollectionCard-main-mode{
    margin-left: 9vw;
    width: 11vw;
    text-align: center;
  }
  
  #FeesCollectionCard-main-delete{
    margin-left: 8vw;
  }
  .FeesCollection-monthlyCollections{
    height: 16vh;
    width: 46%;
  }

  .FeesCollection-upiAndCashDistribution{
    height: 16vh;
    width: 46%;
  }
  .AddAmount-form-header {
    margin-left: 42%;
    font-size: 3vw;
  }

  #AddAmount-form {
    max-width: 50vw;
    margin-left: 27%;
  }

  #AddAmount-submitButton{
    font-size: 2vw;
  }

}

@media screen and (max-width: 767px) {

  .FeesCollection-header{
    margin-left: 5%;
    padding: 10px 0px;
    margin-top: 5%;
    width: 105%;
  }

  .FeesCollection-header-addAmount{
    padding: 5px 5px;
    width:33vw
  }

  .FeesCollectionCard-container {
    max-height: 77vw;
    width: 100%;
    margin-left: 5%;
  }

  .FeesCollectionCard-main{
    padding:10px 10px;
    max-width: 95%;
    margin-right: 2%;
  }

  #FeesCollectionCard-main-name{
    width:25vw;
    text-align: left;
  }

  #FeesCollectionCard-amount{
    margin-left: 7vw;
  }


  #FeesCollectionCard-main-amount{
    margin-left: 13vw;
    width: 10vw;
    text-align: center;
  }
  
  #FeesCollectionCard-main-date{
    margin-left: 12vw;
    width: 15vw;
    text-align: center;
  }

  #FeesCollectionCard-time{
    display: none;
  }
  
  #FeesCollectionCard-main-time{
    display: none;
  }
  
  #FeesCollectionCard-main-mode{
    margin-left: 8vw;
    width: 11vw;
    text-align: center;
  }
  
  #FeesCollectionCard-main-delete{
    margin-left: 2vw;
  }

  .FeesCollection-graphs{
    flex-direction: column;
  }

  .FeesCollection-graphHeader{
    margin-left: 4%;
  }
  .FeesCollection-monthlyCollections{
    height: 28vh;
    width: 107%;
    margin-bottom: 5%;
    margin-left: 14%;
  }

  .FeesCollection-upiAndCashDistribution{
    height: 28vh;
    width: 108%;
    margin-left: 15%;
  }
  .AddAmount-form-header{
    font-size: 6.0vw;
  }
  #AddAmount-form {
    max-width: 96vw;
    margin-left: 5%;
  }

  .AddAmount-input-group-id{
    margin-bottom: 2.5vw;
  }

  .AddAmount-input-group-date{
    margin-bottom: 4.5vw;
  }

  .AddAmount-input-group-amount{
    margin-bottom: 3.5vw;
  }



  #AddAmount-submitButton{
    font-size: 4vw;
    margin-left: 23.4vw;
    margin-top: 4.5vw;
  }

}
