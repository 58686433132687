* {
  margin: 0;
  padding: 0;
}

.MemberCard-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  padding: 0vw 1vw;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.MemberCard-image {
  width: 4vw;
  height: 4vw;
  margin-top: 0.6vw;
  border-radius: 50%;
  overflow: hidden;
}

#MemberCard-name {
  margin-left: 11vw;
  width: 10vw;
  text-align: center;
}

#MemberCard-id {
  margin-left: 8vw;
  width: 10vw;
  text-align: center;
}

#MemberCard-contact {
  margin-left: 8vw;
  width: 10vw;
  text-align: center;
}

#MemberCard-email {
  margin-left: 9vw;
  width: 14vw;
  text-align: center;
}

.MemberList-container {
  padding: 3vw;
}

.MemberList-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw 1vw;
}

.MemberList-addMember {
  background-color: #1a293c;
  color: #ffffff;
  padding: 0.3vw 3vw;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
}

#MemberList-header-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 500;
  font-weight: 1000;
  font-size: 2vw;
}
#MemberList-header-count {
  margin-right: 75%;
  font-family: "Jaldi", "sans-serif";
  font-weight: 1000;
  font-size: 2vw;
}

.MemberCard-container {
  max-height: 32vw;
  overflow-y: auto;
  background-color: #dbdcdd;
}

.MemberList-main {
  padding-left: 3vw;
}

#MemberList-row-id {
  margin-left: 1vw;
}

#MemberList-row-contact {
  margin-right: 3.5vw;
}

#MemberList-row-email {
  margin-right: 3vw;
}

.Search-input {
  padding-left: 3vw;
  padding-right: 2vw; /* Space for the icon */
  background-image: url("../assets/search-icon.svg"); /* Path to your search icon */
  background-repeat: no-repeat;
  background-size: 1.5vw; /* Adjust the size of he icon */
  background-position: 0.8vw 50%; /* Position the icon */
}

#MemberList-searchBar-input {
  text-align: center;
  font-family: "Jaldi", "sans-serif";
  margin-left: 80%;
  padding: 6px 25px;
  margin-bottom: 1%;
  margin-top: 1%;
  border-radius: 12px;
}

.MemberList-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  margin-left: 8%;
  margin-right: 6%;
  font-weight: 600;
}

#MemberList-statistics-header {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-top: 4%;
  margin-left: 1%;
  font-size: 2vw;
}

.MemberList-graphs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.graph-member-gender {
  background-color: #ffffff;
  width: 30vw;
  height: 15vw;
  margin-right: 5vw;
  border: 0.7vw solid #d9d9d9;
}

.graph-member-membership {
  background-color: #ffffff;
}

#graph-member-md {
  width: 30vw;
  height: 15vw;
  margin-right: 5vw;
  border: 0.7vw solid #d9d9d9;
}

#graph-member-ad {
  width: 30vw;
  height: 15vw;
  margin-right: 5vw;
  border: 0.7vw solid #d9d9d9;
  margin-top: 4%;
}
.MemberList-form-header {
  font-family: "Jaldi", "sans-serif";
  font-size: 1.9vw;
  font-weight: 600;
}

.MemberSetup-form {
  margin-left: 20%;
  max-width: 45vw;
  font-weight: 600;
}

.MemberSetup-imgdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 10vw;
  height: 10vw;
  background-color: #d9d9d9;
  border-radius: 50%;
  font-family: "Jaldi", "sans-serif";
  margin-left: 67%;
  margin-bottom: 2vw;
  position: relative;
  overflow: hidden; /* Hide overflow content outside the circle */
  cursor: pointer;
}

.MemberSetup-img-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.MemberSetup-input-group-name {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-name {
  margin-left: 10vw;
  padding: 0.7vw 4vw;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-nameSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-contact {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-contact {
  margin-left: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  padding: 0.7vw 4vw;
  border: none;
  border-radius: 4px;
  text-align: center;
}

.MemberSetup-phoneinput {
  margin-left: 6.6vw;
}

#MemberSetup-contactSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
  margin-right: 10.1vw;
}

.MemberSetup-input-group-email {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-email {
  margin-left: 10.2vw;
  padding: 0.7vw 4vw;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-emailSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-address {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-address {
  margin-left: 9.3vw;
  padding: 0.7vw 4vw;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-addressSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-emergency_contact {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-emergency_contact {
  margin-left: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  padding: 0.7vw 4vw;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.MemberSetup-emergyncyphoneinput {
  margin-left: 2vw;
}

#MemberSetup-emergency_contactSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-id {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-id {
  margin-left: 11.5vw;
  padding: 0.7vw 4vw;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-idSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-gender {
  display: flex;
  align-items: center;
}

#MemberSetup-gender {
  padding: 0.7vw 6.3vw;
  background-color: #d9d9d9;
  margin-bottom: 2vw;
  margin-left: 9.4vw;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  border: none;
  text-align: center;
}

#MemberSetup-genderSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-start-date {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-start-date {
  margin-left: 8.28vw;
  background-color: #d9d9d9;
  padding: 1.0vw 5.6vw;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-startDateSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-birth-date {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-birth-date {
  margin-left: 7.2vw;
  background-color: #d9d9d9;
  padding: 1.0vw 5.6vw;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-birthDateSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-end-date {
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
}

#MemberSetup-end-date {
  background-color: #d9d9d9;
  padding: 1.0vw 5.6vw;
  margin-left: 8.6vw;
  border: none;
  border-radius: 4px;
  text-align: center;
}

#MemberSetup-endDateSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

.MemberSetup-input-group-membership {
  display: flex;
  align-items: center;
}

#MemberSetup-membership {
  padding: 0.7vw 6.6vw;
  background-color: #d9d9d9;
  margin-bottom: 2vw;
  margin-left: 5.3vw;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  border: none;
  text-align: center;
}

#MemberSetup-membershipSpan {
  font-family: "Jaldi", "sans-serif";
  margin: auto;
}

#MemberSetup-submitButton {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 0.5vw 7vw;
  border-radius: 10px;
  margin-left: 40%;
  margin-top: 30px;
  font-family: "Jaldi", "sans-serif";
  font-size: 15px;
  cursor: pointer;
}

#MemberSetup-submitButton:hover {
  transform: scaleY(110%);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .MemberList-container {
    padding: 20px;
    width: 90%;
  }

  .MemberList-header {
    padding: 1vw 7vw;
  }

  .MemberList-addMember {
    padding: 0vw 7vw;
  }

  .MemberList-graphs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MemberList-form-header {
    font-size: 3.5vw;
  }
  .MemberCard-container {
    max-height: 40vw;
    height: 40vw;
    width: 93vw;
  }
  .MemberSetup-form {
    margin-left: 23%;
  }

  .MemberSetup-imgdiv {
    margin-left: 53%;
    width: 13vw;
    height: 13vw;
  }

  #MemberList-header-title {
    margin-left: 5vw;
  }

  #MemberList-header-count {
    font-size: 3vw;
  }
  #MemberList-searchBar-input {
    margin-left: 70%;
  }

  #MemberSetup-contactSpan {
    margin-right: 4.1vw;
  }

  #MemberSetup-emergency_contactSpan {
    margin-right: 3.6vw;
  }

  #MemberSetup-birth-date {
    padding: 0.9vw 6.25vw;
  }
  #MemberSetup-gender {
    padding: 0.7vw 7.2vw;
  }
  #MemberSetup-start-date,
  #MemberSetup-end-date {
    padding: 0.9vw 6.25vw;
  }

  #MemberSetup-membership {
    padding: 0.7vw 7.6vw;
  }

  #MemberSetup-submitButton {
    margin-left: 130px;
  }
  /* Adjust other styles as needed for tablet view */
}

/* For mobile devices */
@media screen and (max-width: 767px) {
  .MemberList-header {
    padding: 1vw 20vw;
  }

  #MemberList-header-title {
    margin-left: 5vw;
    font-size: 6vw;
  }

  #MemberList-header-count {
        font-size: 5.6vw;
  }

  #MemberList-searchBar-input {
    margin-left: 48%;
    padding: 0.7vw 1.5vw;
  }

  .MemberList-addMember {
    font-size: 2.7vw;
    padding: 0vw 8vw;
    margin-left: 2vw;
  }

  #MemberList-statistics-header {
    font-size: 6vw;
  }

  .MemberList-graphs{
    margin-left: 12%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .graph-member-gender, #graph-member-ad, #graph-member-md{
    width:106vw;
    height: 61vw;
    margin-bottom: 4vw;
    margin-left: 7vw;
  }

  .MemberCard-container {
    max-height: 60vw;
    width: 102vw;
    margin-left: 2.5vw;
  }

  .MemberCard-main {
    padding: 0vw 1.5vw;
  }

  #MemberCard-name {
    margin-left: 0.5vw;
    width: 32vw;
  }

  #MemberCard-id {
    margin-left: 2vw;
    width: 20vw;
  }

  #MemberCard-contact {
    margin-left: 2vw;
    width: 30vw;
  }

  #MemberCard-email {
    display: none;
  }

  #MemberList-row-email {
    display: none;
  }

  .MemberCard-image {
    width: 10vw;
    height: 10vw;
    margin-top: 4.5vw;
  }

  .MemberList-form-header {
    font-size: 6vw;
  }

  .MemberSetup-form {
    max-width: 95vw;
    margin-left: 0%;
  }
  .MemberSetup-imgdiv {
    width: 30vw;
    height: 30vw;
    margin-left: 55%;
  }

  .MemberSetup-input-group-name {
    margin-bottom: 5vw;
  }

  #MemberSetup-name {
    width: 53vw;
    padding: 2vw 4vw;
    margin-left: 29vw;
  }

  #MemberSetup-email {
    margin-left: 30vw;
    width: 53vw;
    padding: 2vw 4vw;
  }

  #MemberSetup-address {
    margin-left: 26.2vw;
    padding: 2vw 4vw;
    width: 53.2vw;
  }

  #MemberSetup-idSpan {
    margin: 0;
  }

  .MemberSetup-input-group-id {
    margin-bottom: 5vw;
  }

  #MemberSetup-id {
    padding: 2vw 4vw;
    margin-left: 35.3vw;
    width: 53vw;
  }

  #MemberSetup-gender {
    padding: 2vw 2vw;
    width: 55vw;
  }

  #MemberSetup-genderSpan {
    margin: 0;
    margin-right: 17.5vw;
  }

  .MemberSetup-input-group-contact {
    margin-bottom: 5vw;
  }

  #MemberSetup-contactSpan {
    margin: 0;
    margin-right: 10.7vw;
  }

  #MemberSetup-contact {
    width: 47.1vw;
    padding: 2vw 4vw;
  }
  #MemberSetup-membership {
    margin-left: 10.9vw;
    width: 58vw;
    padding: 2vw 2vw;
  }

  #MemberSetup-birth-date,
  #MemberSetup-start-date,
  #MemberSetup-end-date {
    padding: 3vw 1.5vw;
  }

  #MemberSetup-birth-date {
    width: 66vw;
    margin-left: 22vw;
  }

  #MemberSetup-emergency_contactSpan {
    margin-right: 1.3vw;
    margin: 0;
  }

  #MemberSetup-emergency_contact {
    padding: 2vw 4vw;
    margin-left: 1vw;
    width: 47vw;
  }

  #MemberSetup-startDateSpan,
  #MemberSetup-endDateSpan,
  #MemberSetup-birthDateSpan {
    margin: 0;
  }

  #MemberSetup-start-date {
    width: 53vw;
    margin-left: 22.3vw;
  }

  #MemberSetup-end-date {
    width: 53vw;
    margin-left: 23.5vw;
  }

  #MemberSetup-submitButton{
    padding: 1.5vw 17vw;
    margin-left: 33%;
  }
}
