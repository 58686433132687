/* MemberProfile.css */
*{
  margin: 0;
  padding: 0;
}

#member-profile-header-title {
  margin-left: 42%;
  font-size: 2.0vw;
  font-family: 'Jaldi', 'sans-serif';
}

.member-details {
  margin-left: 30%;
  width:40vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'Jaldi', 'sans-serif';
  font-weight: 600;
}

.profile-image {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 18%;
  border: 4px solid #d9d9d9;

}

.profile-name, .profile-id, .profile-email, .profile-mp, .profile-contact, .profile-sd, .profile-ed{
  margin-bottom: 3vw;
}
#pf-name{
  margin-right: 8vw;
}

#pf-name-data{
  margin-left: 8vw;
}

#pf-id{
  margin-right: 9.4vw;
}

#pf-id-data{
  margin-left: 8vw;
}

#pf-contact{
  margin-right: 7.3vw;
}

#pf-contact-data{
  margin-left: 7.4vw;
}

#pf-sd{
  margin-right: 6.1vw;
}

#pf-sd-data{
  margin-left: 8.1vw;
}

#pf-ed{
  margin-right: 6.7vw;
}

#pf-ed-data{
  margin-left: 8.1vw;
}

#pf-email{
  margin-right: 8vw;
}

#pf-email-data{
  margin-left: 8vw;
}

#pf-mp{
  margin-right: 3.3vw;
}

#pf-mp-data{
  margin-left: 8vw
}

@media  screen and (max-width: 767px) {

  #member-profile-header-title {
    margin-left: 37%;
    font-size: 5vw;
    margin-bottom: 5vw;
    margin-top: 4vw;
}

.profile-image{
  width: 16vw;
  height: 16vw;
}
  .member-details{
    width: 70vw;
    margin-left: 20%;
  }
  #pf-id{
    margin-right: 14.3vw;
  }

  #pf-contact{
    margin-right: 6.4vw;
  }

  #pf-email{
    margin-right: 10vw;
  }

  #pf-sd{
    margin-right: 3.1vw;
  }

  #pf-ed{
    margin-right: 5.7vw;
  }
}