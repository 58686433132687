@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jaldi:wght@400;700&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.member-Dashboard-navbar {
  background-color: #3D2952;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
}

.member-Dashboard-navtop {
  display: flex;
  font-family: "Jaldi";
  align-items: center;
  justify-content: center;
}

#member-Dashboard-navtop-h1 {
  text-align: center;
  font-size: 3.8vw;
  font-weight: 600;
  color: #ffffff;
  margin-left: 30%;
}

#member-Dashboard-navtop-logout {
  margin-left: 35%;
  text-align: right;
  margin-bottom: 2%;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  font-weight: 600;
}

.member-Dashboard-navmiddle {
  display: flex;
  margin-left: 6.2%;
}

.member-Dashboard-navmiddle-texts {
  color: #ffffff;
  margin-left: 2vw;
}

#member-Dashboard-navmiddle-h1 {
  font-family: "Jaldi", "sans-serif";
  font-size: 2.4vw;
  font-weight: 500;
  margin-top: 2.0vw;
  margin-bottom: 0;
}

#member-Dashboard-navmiddle-span {
  font-family: "Jaldi", "sans-serif";
  font-size: 1.7vw;
  margin-left: 0rem;
  margin-top: 0;
}

#member-Dashboard-navmiddle-img {
  width: 10%;
  max-width: 15%;
  border-radius: 50%;
  margin-left: 3%;
  margin-bottom: 4%;
}

.member-Dashboard-navbottom {
  text-align: end;
  margin-bottom: 3.5vw;
  padding: 1vw;
}

#member-Dashboard-navbottom-a {
  color: #000000;
  text-decoration: none;
  font-family: "Jaldi", "sans-serif";
  background-color: #ffffff;
  padding: 3px 23px;
  margin-bottom: 50%;
  margin-right: 4.8%;
  border-radius: 5px;
}

.member-Dashboard-middle {
  margin-top: 3.2%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.member-Dashboard-fee-middle{
    background-color: #3D2952;
    color: #ffffff;
    width: 98%;
    height: 100px;
    text-align: center;
    margin-left: 1%;
    border-radius: 10px;
    margin-bottom: 3%;
}

#member-Dashboard-fee-middle-h2{
    text-align: center;
    font-family: 'Jaldi', 'sans-serif';
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 10px;
}

#member-Dashboard-fee-middle-information{
    font-family: 'Jaldi';
}
/* DashboaradCard */

.member-DashboardCard-main {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1.4% 0.5%;
  background-color: #3D2952;
  color: #ffffff;
  border-radius: 10px;
  max-width: 25%;
  min-width: 25%;
  margin: 1.8%;
  margin-bottom: 2%;
  cursor: pointer;
}

#member-Dashboard-logo-id {
  width: 40%;
}

.member-DashboardCard-Information {
  text-align: center;
  font-family: "Jaldi", "sans-serif";
  font-size: 1.190vw;
  color: #ffffff;
  font-weight: 600;
}

/*media query for tablets and horizontal screens*/
@media screen and (min-width: 768px)and (max-width: 1023px) {
  .member-Dashboard-middle {
    margin-left: 5%;
    margin-right: 5%;
  }

  .member-Dashboard-navtop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #member-Dashboard-navtop-h1 {
    font-size: 4vw;
    margin-left: 38%;
  }

  #member-Dashboard-navtop-logout {
    margin-right: 2.7%;
  }

  .member-Dashboard-navmiddle {
    margin-left: 8%;
  }

  #member-Dashboard-navmiddle-h1 {
    font-size: 3.5vw;
  }

  #member-Dashboard-navmiddle-span {
    font-size: 3vw;
    margin-left: 2%;
  }

  #member-Dashboard-navmiddle-img {
    width: 20%;
    max-width: 30%;
    margin-right: 2%;
  }

  #member-Dashboard-navbottom-a {
    margin-right: 1.5%;
  }

  .member-DashboardCard-main {
    max-width: 50%;
    min-width: 43%;
    padding: 2.4% 0.5%
  }

  #member-Dashboard-logo-id {
    width: 30%;
  }

  .member-DashboardCard-Information {
    font-size: 2vw;
  }
}

/* Media query for mobile phones */
@media screen and (max-width: 767px) {
  .member-Dashboard-navbar {
    width: 108%;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
  }

  .member-Dashboard-navtop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #member-Dashboard-navtop-h1 {
    font-size: 7.5vw;
    margin-right: 0%;
    margin-top: 5%;
    margin-left: 37%;
  }

  #member-Dashboard-navtop-logout {
    margin-left: 0%;
    margin-right: 4%;
    margin-bottom: 0%;
  }

  .member-Dashboard-navmiddle {
    margin-left: 7%;
  }

  .member-Dashboard-navmiddle-texts{
    width:40vw;
  }

  #member-Dashboard-navmiddle-h1 {
    font-size: 5.5vw;
  }

  #member-Dashboard-navmiddle-span {
    font-size: 4.5vw;
    margin-left: 0%;
  }

  #member-Dashboard-navmiddle-img {
    width: 23%;
    max-width: 30%;
    margin-right: 5%;
  }

  .member-Dashboard-navbottom {
    margin-bottom: 10vw;
  }

  .member-Dashboard-middle {
    margin-left: 4%;
    margin-right: 0%;
  }

  .member-DashboardCard-main {
    max-width: 44%;
    min-width: 44%;
  }

  #member-Dashboard-logo-id {
    width: 40%;
  }

  .member-DashboardCard-Information {
    font-size: 4.5vw;
  }
}
