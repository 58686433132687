*{
    margin: 0;
    padding: 0;
}

#Faq-header{
    background-color: #1a293c;
    text-align: center;
    color: #fff;
    font-family: 'Jaldi', 'sans-serif';
    font-weight: 600;
}

#Faq-iframe{
    margin-top: 5%;
    width: 80vw;
    height: 80vh;
    margin-left: 10%;
}

@media screen and (max-width: 767px) {
    #Faq-iframe{
        margin-top: 30%;
        width: 95vw;
        height: 40vh;
        margin-left: 2%;
    }
}