.error-page-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .error-heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .error-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .register-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .register-link:hover {
    background-color: #0056b3;
  }
  