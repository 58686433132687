body {
    overflow: hidden;
    margin: 0;
    font-family: 'Inter', sans-serif;
  }
  
  .error-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(251.63deg, #6f2a88 28.25%, #4881a1 55.13%, #073936);
    position: relative;
    text-align: center;
    color: #fff;
    overflow: hidden;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.3;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .error-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-code {
    font-size: 200px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin: 0 10px;
    animation: float 2s ease-in-out infinite;
  }
  
  .error-code:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .error-code:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  .stars-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    opacity: 0.1;
  }
  
  .message {
    font-size: 40px;
    font-weight: 500;
    margin: 20px 0;
  }
  
  .back-to-home {
    font-size: 20px;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-to-home:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  @media (max-width: 767px) {
    .error-code {
      font-size: 100px;
    }
  
    .message {
      font-size: 24px;
    }
  
    .back-to-home {
      font-size: 18px;
    }
  }
  