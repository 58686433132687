* {
  margin: 0;
  padding: 0;
}

/* mb-Notice Card styles */
.mb-NoticeCard-main {
  display: flex;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  padding: 25px 20px;
  background-color: #3d2952;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 15px;
  max-height: 30px;
}

#mb-NoticeCard-para {
  max-width: 30%;
  margin-left: 31%;

}

#mb-NoticeCard-ed{
  margin-left: 18%;
}

#mb-NoticeCard-delete{
  margin-left: 2vw;
}

/* Notice Information style */
.mb-NoticeInformation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

#mb-NoticeInformation-header-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-left: 1.5%;
}

.mb-NoticeInformation-header-createNotice {
  background-color: #3d2952;
  color: #ffffff;
  padding: 5px 45px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Jaldi", "sans-serif";
}

.mb-NoticeCard-container {
  max-height: 62vh;
  overflow-y: auto;
  background-color: #dbdcdd;
  margin: 1vw;
  padding-left: 3vw;
  width: 94vw;
}

.mb-NoticeInformation-row {
  display: flex;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  margin-left: 2%;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
  font-weight: 600;
}

#mb-NoticeInformation-row-ed{
  margin-left: 18%;
}

#mb-NoticeInformation-row-details{
  margin-left: 31%;
}

#No-notice{
  font-family: 'Jaldi','sans-serif';
  text-align: center;
  font-weight: 600;
  font-size: 1.5vw;
}

/* Media Queries for Responsive Design */

/* For tablets and larger screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mb-NoticeCard-main {
    padding: 25px 5px;
  }
  .mb-NoticeInformation-header {
    padding: 2vw 2vw;
  }

  .mb-NoticeInformation-header-createNotice {
    padding: 10px 60px;
  }

  .mb-NoticeCard-container {
    margin: 2vw;
    padding-left: 5vw;
    max-height: 41vh;
  }

  #mb-NoticeCard-para{
    margin-left: 23%;
  }

  #mb-NoticeCard-ed{
    margin-left: 15%;
  }

  #mb-NoticeInformation-row-ed{
    margin-left: 13%;
  }
  .mb-NoticeInformation-form-header {
    font-size: 4vw;
    margin-left: 40%;
  }

  .mb-NoticeInformation-form {
    margin-left: 32%;
    max-width: 40vw;
  }
}

/* For larger screens */
@media screen and (max-width: 767px) {

  .mb-NoticeInformation-header{
    margin-top: 8%;
  }

  .mb-NoticeInformation-header-createNotice{
    padding: 5px 25px;
  }
  .mb-NoticeCard-main {
    padding: 40px 1px;
  }

  #mb-NoticeCard-ed{
    margin-left: 5%;
    width: 25%;
  }

  #mb-NoticeCard-para{
    margin-left: 5%;
    max-width: 40%;
  }

  #mb-NoticeInformation-row-details{
    margin-left: 8%;
  }

  #mb-NoticeInformation-row-ed{
    margin-left: 5%;
  }

  .mb-NoticeCard-container {
    margin-left: 6%;
    max-height: 68vw;
  }

  .mb-NoticeInformation-form-header{
    font-size: 7vw;
    margin-left: 35%;
  }

  .mb-NoticeInformation-form{
    max-width: 72vw;
    margin-left: 18%;
  }
}
