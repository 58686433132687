* {
  margin: 0;
  padding: 0;
}

.OfferCard-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jockey One", "sans-serif";
  padding: 25px 20px;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.OfferInformation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

#OfferInformation-header-title {
  font-family: "Jockey One", "sans-serif";
  font-weight: 200;
}

.OfferInformation-header-createOffer {
  background-color: #1a293c;
  color: #ffffff;
  padding: 5px 45px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Jaldi", "sans-serif";
}

.OfferCard-container {
  max-height: 500px;
  overflow-y: auto;
  background-color: #dbdcdd;
  margin: 10px;
  padding-left: 30px;
}

.OfferInformation-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jockey One", "sans-serif";
  margin-left: 2%;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
}

#OfferCard-effectiveDate {
  margin-right: 6%;
}

#OfferCard-noticeDetails {
  margin-right: 30%;
}

#OfferCard-startDate {
  margin-right: 15px;
}

#OfferCard-offerPrice {
  margin-right: 20px;
}

.CreateOffer-form-header {
  font-family: "Jockey One", "sans-serif";
  font-size: 30px;
  margin-left: 2%;
}

#createOfferForm {
  margin-left: 2%;
}

.CreateOffer-input-group-start-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#CreateOffer-start-date {
  margin-left: 5px;
  padding: 10px 40px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
}

#CreateOffer-startDateSpan {
  font-family: "Jockey One", "sans-serif";
  margin-right: 70px;
}

.CreateOffer-input-group-end-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#CreateOffer-end-date {
  margin-left: 5px;
  padding: 10px 40px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
}

#CreateOffer-endDateSpan {
  font-family: "Jockey One", "sans-serif";
  margin-right: 70px;
}

.CreateOffer-input-group-offerDuration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#CreateOffer-offerDurationSpan {
  font-family: "Jockey One", "sans-serif";
  margin-right: 70px;
}

#CreateOffer-offerDuration {
  font-family: "Jaldi", "sans-serif";
  padding: 10px 35px;
  background-color: #d9d9d9;
  border-radius: 4px;
}

.CreateOffer-input-group-offerPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#CreateOffer-offerPriceSpan {
  font-family: "Jockey One", "sans-serif";
  margin-right: 70px;
}

#CreateOffer-offerPrice {
  font-family: "Jaldi", "sans-serif";
  padding: 10px 13px;
  background-color: #d9d9d9;
  border-radius: 4px;
  border: none;
  text-align: center;
}

#CreateOffer-submitButton {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 9px 95px;
  border-radius: 10px;
  margin-left: 40px;
  margin-top: 30px;
  font-family: "Jockey One", "sans-serif";
  font-size: 15px;
  cursor: pointer;
}

/* Media Queries for Responsive Design */

/* For tablets and larger screens */

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .OfferInformation-main {
    margin-right: 25px;
  }

  .CreateOffer-form-header {
    margin-left: 10%;
  }

  #CreateOffer-offerPrice {
    margin-left: 22px;
  }

  #CreateOffer-start-date {
    margin-left: -230px;
    margin-right: -66px;
  }

  #CreateOffer-end-date {
    margin-left: -230px;
    margin-right: -66px;
  }

  #CreateOffer-submitButton {
    margin-left: 85px;
  }
}

@media screen and (max-width: 768px) {
  .OfferCard-main {
    padding: 25px 10px;
  }
  .OfferInformation-main {
    margin-right: 20px;
  }

  #CreateOffer-start-date {
    margin-left: -230px;
    margin-right: -2px;
  }

  #CreateOffer-end-date {
    margin-left: -230px;
    margin-right: -2px;
  }
}
