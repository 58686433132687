.dm-nav {
    background-color: #1a293c;
    border-bottom-left-radius: 2%;
    border-bottom-right-radius: 2%;
  }
  
  .dm-navtop {
    display: flex;
    font-family: "Jaldi";
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
  }

  #dm-logo{
    width: 70%;
    margin-left: 56%;
    margin-bottom: 10%;
  }
  
  #dm-navtop-h1 {
    text-align: center;
    font-size: 4.4vw;
    font-weight: 600;
    color: #ffffff;
    margin-left: 35%;
    display: flex;
    align-items: center;
  }
  
  #dm-navtop-logout {
    font-family: 'Jaldi','san-serif';
    margin-left: 31%;
    text-align: right;
    margin-bottom: 2%;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    font-weight: 600;
    margin-right: 1%;
  }

  .dm-navbottom{
    display: flex;
    padding-bottom: 2%;
  }


  #dm-navbottom-left-edit{
    background-color: #ffffff;
    font-family: 'Jaldi';
    padding: 2px 20px;
    border-radius: 8px;
    margin-left: 40%;
    margin-top: 10%;
    cursor: pointer;
    font-weight: 600;
  }

  .dm-navbottom-right{
    width: 60%;
    margin-left: 10%;
    font-family: 'Jaldi';
    color:#ffffff;
  }
  .dm-navbottom-right-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8%;
    width: 60vw;
  }

  .dm-navbottom-right-top-id-main, .dm-navbottom-right-top-email-main, .dm-navbottom-right-top-contact-main, .dm-navbottom-right-top-emergency-main{
    display: grid;
  }

  .dm-navbottom-right-top-contact-main{
    margin-left: 3.2vw;
  }

  .dm-navbottom-right-bottom{
    display: flex;
    align-items: center;
    width: 58vw;
  }

  .dm-navbottom-right-bottom-plan-main, .dm-navbottom-right-bottom-startDate-main, .dm-navbottom-right-bottom-endDate-main, .dm-navbottom-right-bottom-address-main{
    display: grid;
  }
 
  .dm-navbottom-right-bottom-endDate-main{
    margin-left: 7%;
 
  }

  #dm-navbottom-right-bottom-endDate-value{
    width: 12vw;
  }

  .dm-navbottom-right-bottom-startDate-main{
    margin-left: 23%;
  }

  #dm-navbottom-right-bottom-startDate-value{
    width: 12vw;
  }

  .dm-navbottom-right-bottom-address-main{
    margin-left: 14%;
  }

  #dm-navbottom-right-bottom-address-value{
    width: 15vw;
  }

  #dm-navbottom-left-image{
    width:10vw;
    height: 10vw;
    border-radius: 50%;
    margin-bottom: 0.5vw;
    margin-left: 3vw;
  }

  #dm-navbottom-left-name{
    font-size: 2.0vw;
    color: #ffffff;
    font-family: 'Jaldi','sans-serif';
    margin-left: 4.5vw;
    text-align: center;
  }

  .dm-middle{
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    font-family: 'Jaldi', 'sans-serif';
  }

  .dm-middle-attendance-button{
    background-color: #1a293c;
    color: #ffffff;
    padding: 10px 40px;
    border-radius: 12px;
    margin-left: 4vw;
    cursor: pointer;
    font-weight: 600;
  }

  .dm-middle-attendance-button:hover{
    transform: scale(1.05);
  }

  .dm-middle-payment-button{
    background-color: #1a293c;
    color: #ffffff;
    padding: 10px 40px;
    border-radius: 12px;
    margin-right: 4vw;
    cursor: pointer;
    font-weight: 600;
  }

  .dm-middle-payment-button:hover{
    transform: scale(1.05);
  }

  #dm-bottom-title{
    font-family:'Jaldi','sans-serif';
    font-weight: 600;
    margin-left: 9.5%;
  }

  .dm-bottom-form{
    margin-left: 10%;
    max-width:38vw;
    font-weight: 600;
  }

  .dm-bottom-form-imgdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 10vw;
    height: 10vw;
    background-color: #d9d9d9;
    border-radius: 50%;
    font-family: "Jaldi", "sans-serif";
    margin-left: 63%;
    margin-bottom: 2vw;
    position: relative;
    overflow: hidden; /* Hide overflow content outside the circle */
    cursor: pointer;
  }
  
  .dm-bottom-form-img-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .dm-bottom-form-input-group-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-name {
    margin-left: 3.2vw;
    padding: 10px 50px;
    font-family: "Jaldi", "sans-serif";
    background-color: #d9d9d9;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-nameSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  .dm-bottom-form-input-group-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-contact {
    font-family: "Jaldi", "sans-serif";
    background-color: #d9d9d9;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    text-align: center;
  }
  
  #dm-bottom-form-contactSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  .dm-bottom-form-input-group-email {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-email {
    padding: 10px 50px;
    font-family: "Jaldi", "sans-serif";
    background-color: #d9d9d9;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-emailSpan {
    font-family: "Jaldi", "sans-serif";
    
  }
  
  .dm-bottom-form-input-group-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
    
  }
  
  #dm-bottom-form-address {
    padding: 10px 50px;
    font-family: "Jaldi", "sans-serif";
    background-color: #d9d9d9;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-addressSpan {
    font-family: "Jaldi", "sans-serif";
    
  }
  
  .dm-bottom-form-input-group-emergency_contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-emergency_contact {
    font-family: "Jaldi", "sans-serif";
    background-color: #d9d9d9;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    text-align: center;
  }
  
  #dm-bottom-form-emergency_contactSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  .dm-bottom-form-input-group-id {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-id {
    padding: 10px 50px;
    margin-right: 0;
    font-family: "Jaldi", "sans-serif";
    background-color: #d9d9d9;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-idSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  .dm-bottom-form-input-group-gender {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #dm-bottom-form-gender {
    padding: 9px 75px;
    background-color: #d9d9d9;
    margin-bottom: 2vw;
    font-family: "Jaldi", "sans-serif";
    border-radius: 4px;
    border: none;
    text-align: center;
  }
  
  #dm-bottom-form-genderSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  .dm-bottom-form-input-group-start-date {
    display: flex;
    align-items: center;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-start-date {
    background-color: #d9d9d9;
    padding: 12px 67px;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-startDateSpan {
    font-family: "Jaldi", "sans-serif";
    margin-right: 11.2vw;
  }
  
  .dm-bottom-form-input-group-birth-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-birth-date {
    background-color: #d9d9d9;
    padding: 12px 67px;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-birthDateSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  
  .dm-bottom-form-input-group-end-date {
    display: flex;
    align-items: center;
    margin-bottom: 2vw;
  }
  
  #dm-bottom-form-end-date {
    background-color: #d9d9d9;
    padding: 12px 67px;
    border: none;
    border-radius: 4px;
    text-align: center;
  }
  
  #dm-bottom-form-endDateSpan {
    font-family: "Jaldi", "sans-serif";
    margin-right: 11.6vw;
  }
  
  .dm-bottom-form-input-group-membership {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #dm-bottom-form-membership {
    padding: 9px 80px;
    background-color: #d9d9d9;
    margin-bottom: 2vw;
    font-family: "Jaldi", "sans-serif";
    border-radius: 4px;
    border: none;
    text-align: center;
  }
  
  #dm-bottom-form-membershipSpan {
    font-family: "Jaldi", "sans-serif";
  }
  
  #dm-bottom-form-submitButton{
    display: flex;
    align-items: center;
    color: #ffffff;
    background-color: #1a293c;
    padding: 9px 95px;
    border-radius: 10px;
    margin-left: 6vw;
    margin-top: 1.2vw;
    margin-bottom: 1vw;
    font-family: "Jaldi", "sans-serif";
    font-size: 1.0vw;
    cursor: pointer;
  }

  #dm-idminus{
    margin-left: 11vw;
    margin-top: 12vw;
    color:#ffffff;
  }

  @media (max-width: 767px){
    .dm-nav{
      width: 145vw;
    }

    #dm-logo{
      width: 130%;
    }

    #dm-navtop-h1{
      margin-left: 35%;
      font-size: 7.4vw;
    }

    #dm-navtop-logout{
      margin-left: 19%;
      margin-bottom: 1%;
      margin-right: 2%;
    }

    #dm-idminus{
      margin-left: 5%;
      margin-top: 30%;
    }
    #dm-navbottom-left-image{
      width: 110px;
      height: 110px;
    }

    #dm-navbottom-left-name{
      font-size: 20px;
      margin-left: 8.5vw;
    }

    #dm-navbottom-left-edit {
      margin-left: 30%;
    }

    .dm-navbottom-right{
      margin-left: 0%;
    }

    .dm-navbottom-right-top-contact-main{
      margin-left: 12vw;
    }

    .dm-navbottom-right-top{
      flex-direction: column;
      margin-top: 10vw;
      margin-left: 14vw;
      font-size: 5vw;
    }

    #dm-bottom-form-nameSpan{
      margin-right: 24vw;
    }

    #dm-bottom-form-emailSpan{
      margin-right: 27.5vw;
    }

    #dm-bottom-form-contactSpan{
      margin-right: 8.6vw;
    }

    #dm-bottom-form-addressSpan{
      margin-right: 24vw;
    }

    #dm-bottom-form-emergency_contact{
      padding: 10px 49px;
    }

    #dm-bottom-form-emergency_contactSpan{
      margin-right: 3.1vw;
    }

    #dm-bottom-form-idSpan{
      margin-right: 33vw;
    }

    #dm-bottom-form-genderSpan{
      margin-right: 24.3vw;
    }

    #dm-bottom-form-start-date{
      padding: 12px 12px;
      width: 61vw;
    }

    #dm-bottom-form-gender{
      padding: 10px 78px;
    }

    #dm-bottom-form-membershipSpan{
      margin-right: 15vw;
    }

    #dm-bottom-form-membership{
      padding: 9px 82px;
    }

    .dm-navbottom-right-top-id-main{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2vw;
      margin-bottom: 12vw;
    }

    #dm-navbottom-right-top-id{
      margin-right: 22vw;
    }

    #dm-navbottom-right-top-contact{
      margin-right: 10vw;
    }

    .dm-navbottom-right-top-contact-main{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .dm-navbottom-right-top-emergency-main{
      display:none
    }

    .dm-navbottom-right-top-email-main{
      display: none;
    }

    .dm-navbottom-right-bottom{
      display: none;
    }

    #dm-navbottom-right-bottom-startDate-value{
      width: 18vw;
    }

    .dm-navbottom-right-bottom-endDate-main{
      margin-left: 15vw;
    }

    #dm-bottom-form-startDateSpan{
      margin-right: 19.2vw;
    }

    .dm-navbottom-right-bottom-startDate-main{
      margin-left: 16vw;
    }

    .dm-middle-payment-button{
      margin-right: -135px;
    }

    .dm-navbottom-right-bottom-address-main{
      display: none;
    }

    .dm-bottom-form-input-group-end-date, .dm-bottom-form-input-group-start-date{
      width: 118vw;
    }

    #dm-bottom-form-end-date{
      width: 61vw;
      margin-left: 9vw;
      padding: 12px 12px;
    }


    .dm-bottom-form{
      max-width: 95vw;
      margin-left: 8%;
    }

    .dm-bottom-form-imgdiv{
      width: 35vw;
      height: 35vw;
      margin-left: 60%;
      margin-bottom: 3%;
    }
    #dm-bottom-form-submitButton{
      font-size: 4.5vw;
      margin-left: 17vw;
    }

    #dm-home{
      width: 15%;
    }

    .dm-bottom-form-input-group-name,.dm-bottom-form-input-group-contact,.dm-bottom-form-input-group-email,.dm-bottom-form-input-group-birth-date,.dm-bottom-form-input-group-address,.dm-bottom-form-input-group-emergency_contact,.dm-bottom-form-input-group-id,.dm-bottom-form-input-group-gender,.dm-bottom-form-input-group-start-date,.dm-bottom-form-input-group-end-date,.dm-bottom-form-input-group-membership{
      margin-bottom: 6vw;
    }
  }

  @media (min-width:768px) and (max-width:968px){

    .dm-bottom-form{
      max-width: 57vw;
    }

    #dm-navtop-logout{
      margin-left: 30%;
    }

    .dm-navbottom-right-bottom{
      margin-right: 25px;
    }

    #dm-bottom-form-submitButton{
      font-size: 2.5vw;
      margin-left: 9vw;
    }
    
  }