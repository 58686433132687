@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");

.fp-container {
  display: flex;
  height: 100vh;
}

.fp-left-half {
  flex: 40%;
  height: 94.3%;
  background-color: #1a293c;
  text-align: left;
  font-size: 30px;
  font-family: "Jaldi", "sans-serif";
  color: #ffffff;
  padding: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.logo {
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#mainLogo {
  text-align: end;
  width: 20%;
  height: 20%;
  border-radius: 20%;
  margin-left: 80%;
}

.fp-right-half {
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

h2 {
  font-family: roboto, sans-serif;
  font-size: 28px;
  text-align: left;
}

#fp-span {
  margin-top: 0;
  font-size: 20px;
  font-weight: lighter;
}

#Email,
#Password,
#Name {
  text-align: left;
  font-family: 'Jaldi';
  font-weight: 600;
}

.fp-form {
  width: 100%;
  max-width: 300px;
  margin-top: 20px;
  margin-right: 160px;
}

.fp-form-group {
  margin-bottom: 20px;
}

.fp-label {
  display: block;
  margin-bottom: 5px;
}

#fp-nameInput,
#fp-emailInput,
#fp-passwordInput {
  width: 90%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-right: 40px;
}

.password-input {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

#fp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 70px;
  border-radius: 20px;
  cursor: pointer;
  width: 98%;
}

#fp-button:hover {
  background-color: #0056b3;
}

#fp-p {
  margin-top: 10px;
  margin-bottom: 20px;
}

.login-link {
  margin-top: 30px;
  margin-right: 210px;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

.slideshow-image {
  width: 50%;
  transition: opacity 0.5s ease-in-out;
  border-radius: 10px;
  margin-left: 60px;
}

#fp-h1,
#Where-we {
  margin-left: 60px;
}

#Where-we {
  margin-top: 100px;
}
#fp-h1 {
  font-size: 55px;
  margin-top: 7px;
  margin-bottom: 60px;
}

#for {
  font-size: 25px;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 767px) {
  .fp-container {
    flex-direction: column;
    height: auto;
  }

  .fp-left-half {
    flex: 40%;
    padding: 0;
    border-radius: 20px;
    text-align: center;
  }

  #mainLogo {
    width: 40%;
    height: auto;
    margin-left: 60%;
  }

  #fp-h1,
  #Where-we {
    margin-left: 0;
    text-align: center;
  }

  #Where-we{
    display: none;
  }

  #fp-h1{
    font-size: 35px;
    margin-bottom: 0;
  }

  .slideshow-image {
    width: 80%;
    margin-left: 0;
    margin-bottom: 20px;
    display: none;
  }

  .fp-right-half {
    flex: 60%;
    padding: 20px;
    margin: 0;
  }

  .fp-label,
  #fp-nameInput,
  #fp-emailInput,
  #fp-passwordInput {
    width: 100%;
    margin-right: 0;
    margin-left: 70px;
  }

  .fp-form-group {
    margin-bottom: 10px;
  }

  #fp-button {
    width: 100%;
    margin-right: 0;
    padding: 10px;
    margin-left: 70px;
  }

  .login-link {
    margin-top: 10px;
    margin-right: 0;
  }

  .login-link a:hover {
    text-decoration: underline;
  }

  #fp-button:hover {
    background-color: #0056b3;
  }
}
