* {
  margin: 0;
  padding: 0;
}

.attendanceCard-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  padding: 0.1vw 1.0vw;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 1.1vw;
}

.attendanceCard-image{
  width: 3.6vw;
  height: 3.6vw;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 1.0vw;
}

.Attendance-container {
  padding: 3vw;
}

.Attendance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 1.5vh;
}

#Attendance-header-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
}
#Attendance-header-date {
  font-size: 2.0vw;
  margin-right: 82.5%;
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
}

.AttendanceCards-container {
  max-height: 62vh;
  overflow-y: auto;
  background-color: #dbdcdd;
}

.Search-input {
  padding-left: 3.5vw;
  padding-right: 2vw; /* Space for the icon */
  background-image: url("../assets/search-icon.svg"); /* Path to your search icon */
  background-repeat: no-repeat;
  background-size: 1.8vw; /* Adjust the size of the icon */
  background-position: 8px 50%; /* Position the icon */
}

#Attendance-searchBar-input {
  text-align: center;
  font-family: "Jaldi", "sans-serif";
  margin-left: 80%;
  padding: 6px 25px;
  margin-bottom: 1%;
  margin-top: 1%;
  border-radius: 12px;
}

.Attendance-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  margin-left: 6%;
  margin-right: 7%;
  font-weight: 600;
  margin-bottom: 2vw;
}

.Attendance-list-main {
  margin-left: 3%;
}

#Attendance-statistics-header {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-top: 1%;
  margin-left: 1%;
}

.Attendance-graphs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.graph-7day-attendance {
  background-color: #ffffff;
  width:40vw;
  height: 20vw;
  border: 0.7vw solid #d9d9d9;
}

.graph-morning_evening-attendance {
  background-color: #ffffff;
  height: 20vw;
  width: 40vw;
  border: 0.7vw solid #d9d9d9;
}


@media screen and (max-width: 1024px) {
  .Attendance-container {
    padding: 20px;
  }
  /* Adjust other styles as needed for tablet view */
}

/* For mobile devices */
@media screen and (max-width: 767px) {
  .attendanceCard-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Jaldi", "sans-serif";
    padding: 3vw 2vw;
    font-size: 3.5vw;
  }

  .Attendance-list{
    font-size: 4vw;
    margin-left: 9%;
    font-weight: 600;
  }

  #Attendance-list-name{
    margin-left: 0;
  }

  
  #Attendance-list-id{
    margin-left: 0;
  }

  
  #Attendance-list-time{
    margin-left: 0;
  }

  
  #Attendance-list-day{
    margin-left: 0;
  }

  
  #Attendance-list-date{
    display: none;
  }

  #attendanceCard-date{
    display: none;
  }

  .Attendance-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-content: center;
    margin-left: 90px;
    font-size: 35px;
  }

  #Attendance-header-date {
    font-size: 6vw;
    margin-right: 108.5%;
  }

  .attendaneCard-image{
    height: 8.6vw;
    width: 8.6vw;
  }
  #Attendance-searchBar-input{
    margin-left: 45%;
    padding: 0vw 1vw;
  }
  .Attendance-container {
    padding: 15px;
    max-height: 32vh;
  }

  .AttendanceCards-container{
    max-height: 29vh;
    width: 109%;
    margin-left: 2%;
  }

  .attendanceCard-image{
    width:7.6vw;
    height: 7.6vw;
  }
  
  #Attendance-header-title {
    font-size: 7vw;
  }

  #Attendance-statistics-header{
    font-size: 7vw;
    font-weight: 600;
  }

  .Attendance-graphs {
    flex-direction: column;
    margin-left: 7vw;
  }

  .graph-7day-attendance{
    width:101vw;
    height: 53vw;
    margin-bottom: 5vw;
    margin-left: 6vw;
  }

  .graph-morning_evening-attendance{
    width: 101vw;
    height: 53vw;
    margin-bottom: 5vw;
    margin-left: 6vw;
  }

}
