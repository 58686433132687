* {
  margin: 0;
  padding: 0;
}

.GymSetup-nav {
  text-align: center;
  background-color: #1a293c;
  color: #ffffff;
  padding: 10px 10px;
  border-bottom-left-radius: 1.5%;
  border-bottom-right-radius: 1.5%;
}

#GymSetup-heading {
  font-size: 40px;
  font-family: "Jaldi", "Roboto";
  font-weight: 200;
  margin-top: 15px;
}

#GymSetup-heading-span {
  font-size: 25px;
  font-family: "Jaldi", "Roboto";
  font-weight: 200;
}

#GymSetup-heading-p {
  text-align: center;
  font-size: 25px;
  font-family: "Jaldi", "Roboto";
  font-weight: 200;
}

.GymSetup-form {
  margin-left: 35%;
  width: 50vw;
}

#GymSetup-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-top: 20px;
  margin-left: 35%;
}

.GymSetup-imgdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 160px;
  height: 160px;
  background-color: #d9d9d9;
  border-radius: 50%;
  font-family: "Jaldi", "sans-serif";
  margin-left: 25%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden; /* Hide overflow content outside the circle */
  cursor: pointer;
}

.GymSetup-img-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.GymSetup-input-group-name {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-name {
  margin-left: 32px;
  padding: 10px 50px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-nameSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 70px;
}

.GymSetup-input-group-address {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-address {
  margin-left: 20px;
  padding: 10px 50px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-addressSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 70px;
}

.GymSetup-input-group-district {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-district {
  margin-left: 24px;
  padding: 10px 50px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-districtSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 70px;
}

.GymSetup-input-group-pincode {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-pincode {
  margin-left: 19px;
  padding: 10px 50px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-pincodeSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 70px;
}

.GymSetup-input-group-fees {
  display: flex;
  align-items: center;
  font-weight: 600;
}

#GymSetup-monthlyfees {
  margin-left: 40px;
  padding: 10px 50px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-quarterlyfees {
  margin-left: 138px;
  padding: 10px 50px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-halfYearlyfees {
  margin-left: 138px;
  padding: 10px 50px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-yearlyfees {
  margin-left: 138px;
  padding: 10px 50px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-feesSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 70px;
  font-weight: 600;
}

.GymSetup-input-group-timing {
  display: flex;
  align-items: center;
  font-weight: 600;
}

#GymSetup-morningOpeningtime::before {
  content: "Morning opening time";
}

#GymSetup-morningOpeningtime {
  margin-left: 95px;
  padding: 6px 20px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  width: 13.4vw;
  text-align: center;
  font-weight: 600;
}

#GymSetup-morningClosingtime::before {
  content: "Morning closing time";
}

#GymSetup-morningClosingtime {
  margin-left: 135px;
  padding: 6px 20px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  width: 13.4vw;
  text-align: center;
  font-weight: 600;
}
#GymSetup-eveningOpeningtime::before {
  content: "Evening opening time";
}

#GymSetup-eveningOpeningtime {
  margin-left: 135px;
  padding: 6px 20px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  width: 13.4vw;
  text-align: center;
  font-weight: 600;
}

#GymSetup-eveningClosingtime::before {
  content: "Evening closing time";
}
#GymSetup-eveningClosingtime {
  margin-left: 135px;
  padding: 6px 20px;
  margin-bottom: 20px;
  background-color: #d9d9d9;
  border: none;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  width: 13.4vw;
  text-align: center;
  font-weight: 600;
}

#GymSetup-timingSpan {
  font-family: "Jaldi", "sans-serif";
}

.GymSetup-input-group-gender {
  display: flex;
  align-items: center;
  font-weight: 600;
}

#GymSetup-gender {
  padding: 9px 50px;
  background-color: #d9d9d9;
  margin-bottom: 20px;
  margin-left: 37px;
  margin-right: 0px;
  font-family: "Jaldi", "sans-serif";
  border-radius: 4px;
  border: none;
  font-weight: 600;
  text-align: center;
}

#GymSetup-genderSpan {
  font-family: "Jaldi", "sans-serif";
}

.GymSetup-input-group-description {
  display: flex;
  align-items: center;
  font-weight: 600;
}

#GymSetup-description {
  background-color: #d9d9d9;
  margin-left: 67px;
  font-family: "Jaldi", "sans-serif";
  border: none;
  border-radius: 4px;
  resize: none;
  font-weight: 600;
}

#GymSetup-descriptionSpan {
  font-family: "Jaldi", "sans-serif";
}

.GymSetup-input-group-instagram {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-instagram {
  margin-left: 4px;
  margin-top: 20px;
  padding: 10px 50px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-instagramSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 54px;
}

.GymSetup-input-group-owner_name {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-owner_name {
  margin-left: 30px;
  padding: 10px 50px;
  background-color: #d9d9d9;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-owner_nameSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 70px;
}

.GymSetup-input-group-contact {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-contact {
  margin-left: 4px;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  padding: 10px 49px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
}

.PhoneInput {
  margin-left: 17px;
}

#GymSetup-contactSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 28px;
}

#GymSetup-ownersDetails {
  margin-top: 60px;
  margin-bottom: 30px;
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
}

.GymSetup-input-group-designation {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 600;
}

#GymSetup-designation {
  margin-left: 15px;
  margin-right: 0;
  font-family: "Jaldi", "sans-serif";
  background-color: #d9d9d9;
  padding: 10px 50px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;
}

#GymSetup-designationSpan {
  font-family: "Jaldi", "sans-serif";
  margin-right: 50px;
}

#GymSetup-qrGenerateButton {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 10px 65px;
  border-radius: 10px;
  margin-left: 60px;
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: "Jaldi", "sans-serif";
  font-size: 15px;
}

#GymSetup-submitButton {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 9px 95px;
  border-radius: 10px;
  margin-left: 60px;
  margin-top: 30px;
  font-family: "Jaldi", "sans-serif";
  font-size: 15px;
}

.GymSetup-qrCode {
  margin-left: 10%;
  margin-top: 40px;
}

#GymSetup-downloadQRCode {
  display: flex;
  align-items: center;
  color: #ffffff;
  background-color: #1a293c;
  padding: 10px 55px;
  border-radius: 8px;
  font-family: "Jaldi", "sans-serif";
  margin-top: 20px;
}

#GymSetup-qrGenerateButton:hover,
#GymSetup-submitButton:hover,
#GymSetup-downloadQRCode:hover {
  background-color: #2c3d52;
}

@media screen and (max-width: 767px) {
  .GymSetup-nav {
    padding: 5px 5px;
    width: 550px;
  }

  #GymSetup-title {
    margin-left: 43%;
  }

  .GymSetup-form {
    margin-left: 10%;
    width: 110vw;
  }

  .GymSetup-imgdiv {
    margin-left: 45%;
  }

  #GymSetup-gender {
    padding: 9px 54px;
    margin-left: 8.5%;
  }

  #GymSetup-morningOpeningtime {
    width: 53vw;
  }
  #GymSetup-morningClosingtime {
    width: 53vw;
  }
  #GymSetup-eveningOpeningtime {
    width: 53vw;
  }
  #GymSetup-eveningClosingtime {
    width: 53vw;
  }

  #GymSetup-ownersDetails {
    margin-left: 22%;
  }

  #GymSetup-contact {
    padding: 10px 49px;
    margin-left: 4px;
  }

  #GymSetup-description {
    width: 63vw;
  }

  #GymSetup-qrGenerateButton {
    margin-left: 24%;
    padding: 12px 90px;
  }

  #GymSetup-submitButton {
    margin-left: 23%;
    padding: 12px 130px;
  }

  .GymSetup-qrCode {
    margin-left: 35%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Adjustments for GymSetup-nav */
  .GymSetup-nav {
    padding: 8px 8px;
  }

  .GymSetup-form {
    margin-left: 20%;
  }

  .GymSetup-imgdiv {
    margin-left: 48%;
  }

  #GymSetup-gender {
    padding: 9px 54px;
    margin-left: 25%;
  }

  #GymSetup-gymImages {
    margin-left: 66px;
  }

  #GymSetup-ownersDetails {
    margin-left: 22%;
  }

  #GymSetup-instagramSpan {
    margin-right: 72px;
  }

  #GymSetup-qrGenerateButton {
    margin-left: 23%;
    padding: 3px 90px;
  }

  #GymSetup-submitButton {
    margin-left: 28%;
    padding: 12px 95px;
  }

  .GymSetup-qrCode {
    margin-left: 50%;
  }

  #GymSetup-downloadQRCode {
    padding: 5px 73px;
  }
}
