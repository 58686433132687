@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@200;300;400&family=Jaldi:wght@400;700&family=Jockey+One&family=Lato:wght@300;400;700&family=Roboto+Condensed:ital,wght@0,300;0,400;1,300&family=Roboto:wght@100;300;400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

.Dashboard-navbar {
  background-color: #1a293c;
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
}

.Dashboard-navtop {
  display: flex;
  font-family: "Jaldi";
  align-items: center;
  justify-content: center;
}

#Dashboard-logo{
  width: 15%;
  margin-left: 25%;
  margin-bottom: 10%;
}
#Dashboard-navtop-h1 {
  text-align: center;
  font-size: 3.8vw;
  font-weight: 600;
  color: #ffffff;
  margin-right: 30%;
  margin-bottom: 4vw;
}

#Dashboard-navtop-logout {
  text-align: right;
  margin-bottom: 2%;
  margin-right: 6.0%;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  text-decoration: none;
  font-family: "Jaldi","sans-serif";
  font-weight: 600;
}

.Dashboard-navmiddle {
  display: flex;
  margin-left: 6.2%;
}

.Dashboard-navmiddle-texts {
  color: #ffffff;
  margin-left: 2vw;
}

#Dashboard-navmiddle-h1 {
  font-family: "Jaldi", "sans-serif";
  font-size: 2.4vw;
  font-weight: 500;
  margin-top: 2.0vw;
  margin-bottom: 0;
}

#Dashboard-navmiddle-span {
  font-family: "Jaldi", "sans-serif";
  font-size: 1.7vw;
  margin-left: 0rem;
  margin-top: 0;
}

#Dashboard-navmiddle-img {
  width: 10%;
  max-width: 15%;
  border-radius: 50%;
  margin-left: 3%;
  margin-bottom: 4%;
}

.Dashboard-navmiddle-qr{
  margin-left: 0vw;
  margin-top: 0.7vw;
}

#qr-code-canvas{
  display: none;
}
#Dashboard-navmiddle-download{
  width:2.5vw;
  background-color: #1a293c;
}

.Dashboard-navbottom {
  text-align: end;
  margin-bottom: 3.5vw;
  padding: 1vw;
}

#Dashboard-navbottom-a {
  text-decoration: none;
  color: #000000;
  font-family: "Jaldi", "sans-serif";
  background-color: #ffffff;
  padding: 3px 23px;
  margin-bottom: 50%;
  margin-right: 4.8%;
  border-radius: 5px;
  font-weight: 600;
}

.Dashboard-middle {
  margin-top: 3.2%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* DashboaradCard */

.DashboardCard-main {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 1.4% 0.5%;
  background-color: #1a293c;
  border-radius: 10px;
  max-width: 25%;
  min-width: 25%;
  margin: 1.8%;
  margin-bottom: 2%;
  cursor: pointer;
}

#Dashboard-logo-id {
  width: 40%;
}

.DashboardCard-Information {
  text-align: center;
  font-family: "Jaldi", "sans-serif";
  font-size: 1.190vw;
  color: #ffffff;
  font-weight: 600;
}

/*media query for tablets and horizontal screens*/
@media screen and (min-width: 768px)and (max-width: 1023px) {
  .Dashboard-middle {
    margin-left: 5%;
    margin-right: 5%;
  }

  .Dashboard-navtop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #Dashboard-navtop-h1 {
    font-size: 4vw;
  }

  #Dashboard-logo{
    width: 23%;
    margin-left: 32%;
    margin-bottom: 10%;
  }

  #Dashboard-navtop-logout {
    margin-right: 2.7%;
  }

  .Dashboard-navmiddle {
    margin-left: 8%;
  }

  .Dashboard-navmiddle-texts{
    margin-left: 3%;
    margin-top:2%;
  }

  #Dashboard-navmiddle-h1 {
    font-size: 3.5vw;
  }

  #Dashboard-navmiddle-span {
    font-size: 3vw;
    margin-left: 2%;
  }

  #Dashboard-navmiddle-img {
    width: 20%;
    max-width: 30%;
    margin-right: 2%;
  }
  
  #Dashboard-navbottom-a {
    margin-right: 1.5%;
  }

  .DashboardCard-main {
    max-width: 50%;
    min-width: 43%;
    padding: 2.4% 0.5%
  }

  #Dashboard-logo-id {
    width: 30%;
  }

  .DashboardCard-Information {
    font-size: 2vw;
  }
}

/* Media query for mobile phones */
@media screen and (max-width: 767px) {
  .Dashboard-navbar {
    width: 113%;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
  }

  .Dashboard-navtop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #Dashboard-logo{
    width:45%;
    margin-bottom: 20%;
  }
  #Dashboard-navtop-h1 {
    font-size: 7.5vw;
    margin-right: 15%;
    margin-bottom: 10%;
  }

  #Dashboard-navtop-logout {
    margin-left: 0%;
    margin-right: 2%;
    margin-bottom: 9%;
  }

  .Dashboard-navmiddle {
    margin-left: 7%;
  }

  #Dashboard-navmiddle-h1 {
    font-size: 5.5vw;
  }

  #Dashboard-navmiddle-span {
    font-size: 4.5vw;
    margin-left: 5%;
  }

  .Dashboard-navmiddle-texts{
    margin-top: 2%;
  }

  #Dashboard-navmiddle-img {
    width: 23%;
    max-width: 30%;
    margin-right: 5%;
    margin-bottom: 0%;
  }

  #Dashboard-navmiddle-download{
    width: 7.5vw;
    margin-left: 1.5vw;
  }

  .Dashboard-navbottom {
    margin-bottom: 10vw;
    padding-bottom: 3vw;
  }

  #Dashboard-navbottom-a {
    margin-right: 2vw;
    display: none;
  }

  .Dashboard-middle {
    margin-left: 2%;
    width: 109vw;
    margin-right: 0%;
  }

  .DashboardCard-main {
    max-width: 45%;
    min-width: 45%;
    height: 22vw;
  }

  .DashboardCard-Information {
    font-size: 4.5vw;
  }

  #Dashboard-logo-id{
    width: 45%;
  }
}
