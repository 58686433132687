* {
    margin: 0;
    padding: 0;
  }
  
  .member-attendanceCard-main {
    display: flex;
    align-items: center;
    font-family: "Jaldi", "sans-serif";
    padding: 1.5vw 1vw;
    background-color: #3D2952;
    color: #ffffff;
    max-width: 95%;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .member-Attendance-container {
    padding: 30px;
  }
  
  .member-Attendance-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  #member-Attendance-header-title {
    font-family: "Jaldi", "sans-serif";
    font-weight: 600;
  }
  #member-Attendance-header-date {
    font-size: 1.7vw;
    margin-right: 82.5%;
    font-family: "Jaldi", "sans-serif";
    font-weight: 600;
    width: 102vw;
  }
  
  .member-AttendanceCards-container {
    max-height: 62vh;
    overflow-y: auto;
    background-color: #dbdcdd;
  }

  .member-Attendance-headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .member-Attendance-qrScanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Jaldi", "sans-serif";
    padding: 0.3vw 1.0vw;
    margin-bottom: 1%;
    margin-top: 1%;
    border-radius: 12px;
    background-color: #3D2952;
    color:#ffffff;
    display: none;
  }

  #member-attendance-qrScanner-logo{
    margin-right: 2vw;
  }
  
  .member-Attendance-list {
    display: flex;
    align-items: center;
    font-family: "Jaldi", "sans-serif";
    margin-left: 7%;
    margin-right: 5%;
    font-weight: 600;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  #mb-attendance-list-id{
    margin-left: 17vw;
  }

  #mb-attendance-list-time{
    margin-left: 17vw;
  }

  #mb-attendance-list-day{
    margin-left: 16.5vw;
  }

  #mb-attendance-list-date{
    margin-left: 16.5vw;
  }

  #mb-no-attendance{
    font-family: 'Jaldi', 'sans-serif';
    text-align: center;
    font-weight: 600;
    font-size: 3vw;
  }
  .member-Attendance-list-main {
    margin-left: 3%;
  }

  #member-attendanceCard-main-name{
    width: 12vw;
    text-align: center;
  }

  #member-attendanceCard-main-id{
    width: 12vw;
    margin-left: 10vw;
    text-align: center;
  }

  #member-attendanceCard-main-time{
    width: 10vw;
    margin-left: 10vw;
    text-align: center;
  }

  #member-attendanceCard-main-day{
    width: 12vw;
    margin-left: 10vw;
    text-align: center;
  }

  #member-attendanceCard-main-date{
    width: 12vw;
    margin-left: 10vw;
    text-align: center;
  }
  
  #member-Attendance-statistics-header {
    font-family: "Jaldi", "sans-serif";
    font-weight: 600;
    margin-top: 1%;
    margin-left: 1%;
  }
  
  .member-Attendance-graphs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .member-graph-7day-attendance {
    background-color: #fff;
    width:40vw;
    height: 20vw;
    border: 1.0vw solid #d9d9d9;
  }
  
  .member-Attendance-footer{
    font-family: 'Jaldi';
    font-weight: 600;
    font-size: large;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .member-Attendance-container {
      padding: 20px;
    }

    .member-AttendanceCards-container{
      max-height: 27vh;
    }
    /* Adjust other styles as needed for tablet view */
  }
  
  /* For mobile devices */
  @media screen and (max-width: 767px) {
    .member-attendanceCard-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Jaldi", "sans-serif";
      padding: 4vw 1vw;
      font-size: 3.5vw;
    }
  
    .member-Attendance-header {
      display: flex;
      align-items: center;
      padding: 10px 1px;
      justify-content: center;
      margin-left: 90px;
      font-size: 3.5vw;
    }
  
    #member-Attendance-header-date {
      font-size: 6vw;
    }


    #mb-attendance-list-day{
      display: none;
    }

    #member-attendanceCard-main-time{
      width: 16vw;
      margin-left: 5vw;
    }

    #member-attendanceCard-main-name{
      width: 16vw;
    }

    #member-attendanceCard-main-id{
      width: 15vw;
      margin-left: 4vw;
    }

    #member-attendanceCard-main-date{
      width: 20vw;
      margin-left: 1vw;
    }

    #member-attendanceCard-main-day{
      display: none;
    }

    .member-Attendance-qrScanner{
      margin-left: 0vw;
      padding: 1vw 3vw
    }

    #qrScannerSpan{
      margin-left: 2vw;
    }
    .member-Attendance-container {
      padding: 0;
    }

    .member-AttendanceCards-container{
      max-height: 34vh;
      width: 97vw;
      margin-left: 6%;
    }

    .member-Attendance-header{
      margin-left: 8px;
    }
    #member-Attendance-header-title {
      font-size: 7vw;
      margin-left: 3vw;
    }

    #member-Attendance-statistics-header{
      margin-left: 9%
    }
  
    .member-Attendance-graphs {
      margin: 15px;
    }

    .member-graph-7day-attendance {
      margin-left: 6vw;
      width: 80vw;
      height: 40vw;
      border: 4.7vw solid #d9d9d9;
    }

    .member-Attendance-footer{
      margin-left: 6vw;
    }
  }