* {
  margin: 0;
  padding: 0;
}

.imac-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  padding: 15px 20px;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 95%;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.ima-container {
  background-color: #dbdcdd;
  width: 90%;
  max-height: 44vh;
  overflow-y: auto;
  margin-bottom: 2vw;
  margin-left: 5%;
}

.ima-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  margin-left: 60px;
  font-weight: 600;
}

#ima-row-date {
  margin-right: 50px;
}

#ima-row-day {
  margin-right: 20px;
}

#ima-row-id {
  margin-left: 25px;
}


@media (max-width: 767px) {
  .imac-main {
    max-width: 86%;
  }
  .ima-container{
    width: 135%;
  }
}

@media (min-width: 768px) and (max-width: 968px) {
  .imac-main {
    max-width: 90%;
  }
}
