@import url('https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&display=swap');



body{
  
    overflow-x: hidden; /* Disables horizontal scrollbar */
    overflow-y: auto; 
}



.flex-container1{
    display: flex;
  flex-grow: 1;
  padding-top: 0;
  flex-wrap: wrap;
  width: 104.931vw; 
  height: 82vw;
  background-color: rgba(26, 40, 61, 1);
}
.about-us2 img{
  
   
    width: 100vw; 
    height: 35vw;
    margin-right: 80px;
   
}
.flex-itemA{
    width: 83.333vw;
     height: 60vw;
     background-color:rgba(26, 40, 61, 1);
     margin-left: 8vw;
     margin-top: 5vw;
     margin-bottom: 3.309vw;
     color: #eceff1;
 }
   
 .flex-container1 .para1{
   font-size: 1.8vw;
   color: #eceff1;
   text-align: left;
    padding-left: 3.309vw;
   margin-right: 3.309vw;
   font-family: jaldi,sans-serif;
   left:50px;
 
   }
   .flex-itemA .para2 p{
       font-size: 1.8vw;
       color: #eceff1;
       text-align: left;
       padding-left: 50px;
       margin-right: 50px;
       font-family: jaldi , sans-serif;
       padding-bottom: 10px;
     }
     .s3{
        position: absolute;
        top: 127vw;
        left: 30vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 4vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
    
      }
      .Flex-container2{
        display:flex;
        flex-wrap:wrap;
        width: 104.931vw; 
        height: 35vw;
        background-color: rgba(26, 40, 61, 1);
      }
      .Flex-item2{
        width: 30vw;
        height: 22vw;
         background-color:rgba(26, 40, 61, 1);
         margin-left: 12vw;
         margin-top: 5vw;
         margin-bottom: 3.4vw;
         color: #eceff1;
     }
     .Flex-item2 img{
      height:25vw;
     }
     .Flex-item3{
        width: 38vw;
        height: 30vw;
        background-color:rgba(26, 40, 61, 1);
        margin-left: 10vw;
        margin-top: 5vw;
        margin-bottom: 3.4vw;
        color: #eceff1;
       }
       .Flex-item3 p{
        font-size: 1.5vw;
        font-family: jaldi,sans-serif;
        text-align: left;
        padding-left: 1.3vw;
        padding-top: 0;
    
        
       }

       .Flex-container3{
        display:flex;
        flex-wrap:wrap;
        width: 104vw;
        height: 50vw;
        background-color: rgba(26, 40, 61, 1);
      }
      .s4{
        position: absolute;
        top: 169vw;
        left: 30vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 4vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
    
      }

     .Flex-item4{
    
        width: 1220px;
        height:50px;
        margin-left: 9.8vw;
        background-color:rgba(26, 40, 61, 1);
        margin-right: 20px;
        color: #eceff1;
       }
       .Flex-item4{
        font-size: 1.7vw;
        text-align: left;
        padding-top: 2.5vw;
        color: #eceff1;
        font-family: jaldi,sans-serif;
       }
      
       .Flex-item5{
        width: 41.6vw;
        height: 31.2vw;
         background-color:rgba(26, 40, 61, 1);
         margin-left: 9vw;
         margin-top: 6vw;
         margin-bottom: 3.4vw;
         font-size: 1.7vw;
        text-align: left;
        
        padding-left: 1.3vw;
        color: #eceff1;
        font-family: jaldi,sans-serif;
    
     }
     
     .Flex-item6{
      width: 34.7vw;
      height: 24.306vw;
      background-color:rgba(26, 40, 61, 1);
      margin-left: 10.317vw;
      margin-top: 7vw;
      margin-bottom: 50px;
      color: aliceblue;
     }
     .Flex-item6 img{
      height: 27vw;
     }
    
      .Flex-container4{
        display:flex;
        flex-wrap:wrap;
        width: 104vw;
        height: 38vw;
        background-color:rgba(26, 40, 61, 1);
      }
      .Flex-item7{
        width: 20.3vw;
        height: 20.3vw;
        background-color:rgba(26, 40, 61, 1);
        margin-left:10.417vw;
        margin-top: 3.4vw;
        margin-bottom: 3.4vw;
        color: #eceff1;
       }
       .Flex-item7 img{
        width: 20.3vw;
        height: 20.3vw;
       }
       .Flex-item8{
        width: 38.194vw;
        height: 24.3vw;
        background-color:rgba(26, 40, 61, 1);
        margin-left: 19.4vw;
        margin-top: 3vw;
        margin-bottom: 3.4vw;
        font-family: jaldi,sans-serif;
        font-size: 1.8vw;
        color: #eceff1;
        text-align: left;
       }
       .Flex-item9{
        background-color:rgba(26, 40, 61, 1);
        position: absolute;
        top: 257vw;
        margin-left: 9vw;
        color: #eceff1;
        font-size: 1.3vw;
        width: 25vw;
        height: 7vw;
       }
       .S5{
        position: absolute;
        top: 225vw;
        left: 38vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 4.1vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
    
      }
      .Flex-container4{
        display:flex;
        flex-wrap:wrap;
        width: 104vw;
        height: 38vw;
        background-color:rgba(26, 40, 61, 1);
      }
      .Flex-item11{
        width: 20.8vw;
        height: 20.8vw;
        background-color:rgba(26, 40, 61, 1);
        margin-left: 63.8vw;
        margin-top: 3.4vw;
        margin-bottom: 3.4vw;
        color: #eceff1;
       }
       .Flex-item11 img{
        width: 20.8vw;
        height: 20.8vw;
       }
       .Flex-item13{
        background-color:rgba(26, 40, 61, 1);
        position: absolute;
        top: 297vw;
        margin-left: 62vw;
        color: #eceff1;
        font-size: 1.3vw;
        width: 25vw;
        height: 7vw;
        text-align: center;
       }
       .Flex-item12{
        position: absolute;
        width: 38.194vw;
        height: 24.306vw;
        background-color:rgba(26, 40, 61, 1);
        margin-left: 12.5vw;
        margin-top: 3vw;
        margin-bottom: 50px;
        font-size: 1.8vw;
        color: #eceff1;
        text-align: left;
        font-family: jaldi,sans-serif;
       }
       
       
      