* {
  margin: 0;
  padding: 0;
}

.FeeDueListCard-main {
  display: flex;
  align-items: center;
  font-family: "Jaldi", "sans-serif";
  padding: 25px 20px;
  background-color: #1a293c;
  color: #ffffff;
  max-width: 90%;
  border-radius: 8px;
  margin-bottom: 1.2vw;
  margin-left: 4%;
}

#FeeDueListCard-main-name{
  width:12vw;
}

#FeeDueListCard-main-id{
  width: 10vw;
  margin-left: 1vw;
  text-align: center;
}

#FeeDueListCard-main-endDate{
  width:12vw;
  margin-left: 5vw;
  text-align: center;
}

#FeeDueListCard-main-status{
  width: 15vw;
  margin-left: 4vw;
  text-align: center;
}

#FeeDueListCard-main-dot{
  margin-left: 7vw;
}

#FeeDueList-header-title {
  font-family: "Jaldi", "sans-serif";
  font-weight: 600;
  margin-left: 9.9vw;
  margin-top: 4%;
}

.FeeDueListCard-container {
  width: 80%;
  margin-left: 10%;
  max-height: 28vw;
  overflow-y: auto;
  background-color: #dbdcdd;
  margin-bottom: 2%;
}

.FeesDueList-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jaldi", "sans-serif";
  margin-left: 8%;
  margin-right: 5%;
  margin-bottom: 1%;
  margin-top: 1%;
  font-weight: 600;
}

#FeesDueListCard-id {
  margin-left: 4%;
}

#FeesDueListCard-endDate {
  margin-left: 4%;
}

.FeeDueList-footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Jaldi", "sans-serif";
}

@media screen and (max-width: 767px) {
  .FeeDueList-main {
    margin-right: 10px;
  }
  .FeesDueList-row {
    margin-left: 10%;
  }

  #FeesDueListCard-id {
    margin-left: 18%;
  }

  .FeeDueListCard-container{
    max-height: 40vh;
    width: 100%;
    margin-left: 7%;
  }

  #FeeDueListCard-main-name {
    width: 28vw;
  }

  #FeeDueListCard-main-id {
    width: 15vw;
  }

  #FeeDueListCard-main-endDate {
    width: 18vw;
    margin-left: 7vw;
  }

  #FeeDueListCard-main-dot {
    margin-left: 10vw;
  }

  #FeeDueListCard-main-status{
    display: none;
  }

  #FeesDueListCard-status{
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #FeesDueListCard-id {
    margin-left: 14%;
  }

  #FeesDueListCard-endDate {
    margin-left: 8%;
  }

  .FeeDueListCard-container{
    max-height: 33vh;
  }
}
