@import url('https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&display=swap');

body{
    overflow-y: auto; 
}


.header-items {
  position: absolute;
  top: 1.3vw;
  left: 5vw;
  width: 65vw;
  height: 1.2vw;
  font-size: var(--font-size-xl);
  
}


.header-item1,
.header-item2,
.header-item3,
.header-item4,
.header-item5,
.header-item6 {
    text-decoration: none;
    color: #eceff1; 
    font-size: 1.2vw; 
    font-weight: bold; 
    padding: 10px 14px; 
    transition: all 0.3s ease; 
    margin-right: 40px; 
}

.header-item1:hover,
.header-item2:hover,
.header-item3:hover,
.header-item4:hover,
.header-item5:hover,
.header-item6:hover {
    background-color: #ddd; 
    color: #000; 
    border-radius: 4px; 
}

 .about-us{
    width: 120vw;
    height: 35vw;
    background-color:rgba(61, 41, 82, 1);
    ;
 }
    .signup {
      position: absolute;
      top: .6vw;
      left: 88vw;
      font-size: 1.5vw;
      color: aliceblue;
      line-height: 18px;
    }
    
   .h1{
      position: absolute;
      top: 13.8vw;
      left: 42vw;
      text-align: center;
      
      font-size: 3.4vw;
      color: rgb(217, 220, 222);
      line-height: 18px;
   }
   .p{
      position: absolute;
      top: 20.8vw;
      left: 32vw;
      text-align: center;
      font-size: 2.6vw;
      color: rgb(217, 220, 222);
      line-height: 18px;
   }
   
    .login {
      position: absolute;
      top: 0.7vw;
      left: 80vw;
      font-size: 1.5vw;
      color: rgb(1, 12, 21);
      line-height: 1.25vw;
    }
    .login-button {
      position: absolute;
      top: 0.4vw;
      left: 78.5vw;
      width: 7vw;
      height: 2.2vw;
      border-radius: 0.8vw;
      font-size: var(--font-size-xl);
      color: black;
      background-color: rgb(239, 244, 243);
    }
    .login:hover {
      color:rgb(22, 170, 181);
  }
  .signup:hover{
      color:rgb(7, 99, 105);
      background-color: #ddd; 
   
       border-radius: 4px; 
       padding: 5px;
       border-radius: 8px;
  }
  .white-container{
    top: 685px;
    width: 120vw;
    height: 6.8vw;
    background-color: white;
  }
  .white-container2{
    top: 685px;
    width: 120vw;
    height: 30px;
    background-color: white;
  }
  
  .flex-container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    width: 104.931vw; 
    height: 84vw;
    background-color:rgba(61, 41, 82, 1);
  }
  
  .flex-item1{
     width: 83.333vw;
      height: 60vw;
      background-color:rgba(61, 41, 82, 1);
      margin-left: 8vw;
      margin-top: 5vw;
      margin-bottom: 3.309vw;
      color: #eceff1;
  }
    
  .flex-container .p1{
    font-size: 1.8vw;
    color: #eceff1;
    text-align: left;
     padding-left: 3.309vw;
    margin-right: 3.309vw;
    font-family: jaldi,sans-serif;
    left:50px;
  
    }
    .flex-item1 .p2 p{
        margin-top: 2vw;
        font-size: 1.8vw;
        color: #eceff1;
        text-align: left;
        padding-left: 50px;
        margin-right: 50px;
        font-family: jaldi , sans-serif;
        padding-bottom: 10px;
      }
      .sec2{
        position: absolute;
        top: 38vw;
        left: 40vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 3.5vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
      }
  
  
      .sec3{
        position: absolute;
        top: 128vw;
        left: 26vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 4vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
    
      }
      .sec4{
        position: absolute;
        top: 170vw;
        left: 30vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 4vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
    
      }
      .sec5{
        position: absolute;
        top: 237vw;
        left: 38vw;
        text-align: center;
        font-family: jaldi,sans-serif;
        font-size: 4.1vw;
        color: rgb(17, 19, 20);
        line-height: 1.25vw;
    
      }
      .flex-container2{
        display:flex;
        flex-wrap:wrap;
        width: 104vw;
        height: 35vw;
        background-color:rgba(61, 41, 82, 1);
      }
      .flex-container3{
        display:flex;
        flex-wrap:wrap;
        width: 104vw;
        height: 60vw;
        background-color: rgba(61, 41, 82, 1);
      }
      .flex-container4{
        display:flex;
        flex-wrap:wrap;
        width: 104vw;
        height: 38vw;
        background-color:rgba(61, 41, 82, 1);
      }
      .flex-item2{
        width: 30vw;
        height: 22vw;
         background-color:rgba(61, 41, 82, 1);
         margin-left: 7vw;
         margin-top: 4vw;
         margin-bottom: 3.4vw;
         color: #eceff1;
     }
     .flex-item2 img{
      height:25vw;
     }
     .flex-item3{
      width: 38vw;
      height: 30vw;
      background-color:rgba(61, 41, 82, 1);
      margin-left: 14vw;
      margin-top: 2vw;
      margin-bottom: 3.4vw;
      color: #eceff1;
     }
     .flex-item3 p{
      font-size: 1.5vw;
      font-family: jaldi,sans-serif;
      text-align: left;
      padding-left: 1vw;
      padding-top: 2px;

  
      
     }
     
    
     .flex-item5{
      width: 41.6vw;
      height: 31.2vw;
       background-color:rgba(61, 41, 82, 1);
       margin-left: 6.2vw;
       margin-top: 5vw;
       margin-bottom: 3.4vw;
       font-size: 1.8vw;
      text-align: left;
      
      padding-left: 1.3vw;
      color: #eceff1;
      font-family: jaldi,sans-serif;
  
   }
   
   .flex-item6{
    width: 38vw;
    height: 29vw;
    background-color:rgba(61, 41, 82, 1);
    margin-left: 8vw;
    margin-top: 12vw;
    margin-bottom: 50px;
    color: aliceblue;
   }
   .flex-item6 img{
    height: 35vw;
   }
   .flex-item7{
    width: 20.3vw;
    height: 20.3vw;
    background-color:rgba(61, 41, 82, 1);
    margin-left:10.417vw;
    margin-top: 3.4vw;
    margin-bottom: 3.4vw;
    color: #eceff1;
   }
   .flex-item7 img{
    width: 20.3vw;
    height: 20.3vw;
   }
   .flex-item8{
    width: 38.194vw;
    height: 24.3vw;
    background-color:rgba(61, 41, 82, 1);
    margin-left: 19.4vw;
    margin-top: 2vw;
    margin-bottom: 3.4vw;
    font-family: jaldi,sans-serif;
    font-size: 1.8vw;
    color: #eceff1;
    text-align: left;
   }
   .flex-item9{
    background-color:rgba(61, 41, 82, 1);
    position: absolute;
    top: 268vw;
    margin-left: 8vw;
    color: #eceff1;
    font-size: 1.5vw;
    width: 28vw;
    height: 7vw;
   }
   
   .flex-item11{
    width: 20.8vw;
    height: 20.8vw;
    background-color:rgba(61, 41, 82, 1);
    margin-left: 63.8vw;
    margin-top: 3.4vw;
    margin-bottom: 3.4vw;
    color: #eceff1;
   }
   .flex-item11 img{
    width: 20.8vw;
    height: 20.8vw;
   }
   .flex-item13{
    background-color:rgba(61, 41, 82, 1);
    position: absolute;
    top: 308vw;
    margin-left: 62vw;
    color: #eceff1;
    font-size: 1.7vw;
    width: 25vw;
    height: 6vw;
    text-align: center;
   }
   .flex-item12{
    position: absolute;
    width: 38.194vw;
    height: 24.306vw;
    background-color:rgba(61, 41, 82, 1);
    margin-left: 12.5vw;
    margin-top: 3vw;
    margin-bottom: 50px;
    font-size: 1.8vw;
    color: #eceff1;
    text-align: left;
    font-family: jaldi,sans-serif;
   }
   
   
  