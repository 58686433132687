*{
    margin:0;
    padding:0;
}

.impc-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Jaldi", "sans-serif";
    padding: 15px 20px;
    background-color: #1a293c;
    color: #ffffff;
    width: 95%;
    border-radius: 8px;
    margin-bottom: 15px;
    margin-left: 20px;
}

.imp-container{
    background-color: #DBDCDD;
    max-height: 44vh;
    overflow-y: auto;
    width: 90%;
    margin-left: 5%;
}

.imp-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Jaldi', 'sans-serif';
    margin-left: 40px;
}

#imp-row-mode{
    margin-right: 30px;
}

#imp-row-time{
    margin-right: 10px;
}

#imp-row-date{
    margin-right: 30px;
}

@media (max-width: 767px) {
    .impc-main{
        width: 86%;
    }

    .imp-container{
        width: 133%;
    }
}

@media (min-width:768px) and (max-width: 968px){
    .impc-main{
        max-width: 90%;
    }
}